import React, { useState, useEffect, useMemo } from "react";
import { TestRowType } from "interfaces/Global";
import Table from "components/Table/Table";
import Checkbox from "components/Checkbox";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Badge from "components/Badge";
import TooltipV2 from "components/TooltipV2/TooltipV2";
import TableHeader from "components/TableHeader/TableHeader";

export interface Props {
  items: TestRowType[];
  selectedItems: (_selectedItems: number[]) => void;
  perPage: number;
  currentPage: number;
}

function TestsTable({ selectedItems, items, perPage, currentPage }: Props) {
  const paginationItems = useMemo(() => {
    const obegone = items.slice(
      perPage * (currentPage - 1),
      perPage * currentPage
    );
    return obegone;
  }, [items, perPage, currentPage]);
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(paginationItems.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id: number, checked: boolean) => {
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-lg relative">
      <TableHeader withSearch>
        <h2 className="font-semibold text-slate-800">
          All Tests{" "}
          <span className="text-slate-400 font-medium">{items.length}</span>
        </h2>
      </TableHeader>

      <Table
        headers={[
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select all</span>
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
            </label>
          </div>,
          "Name",
          "Date",
          "Department",
          "Sequence",
          "Form",
          "Status",
          "URL Output",
          "Test Type",
          <div className="text-center">Actions</div>,
        ]}
        data={paginationItems}
        renderItem={(item) => {
          const statusType = () => {
            switch (item.status) {
              case "Completed":
                return "success";
              case "Warning":
                return "warning";
              case "Error":
                return "danger";
              default:
                return "default";
            }
          };

          const typeIcon = () => {
            switch (item.type) {
              case "Recurring":
                return <Icon name="refresh-ccw" className="text-slate-400" />;
              default:
                return (
                  <Icon name="git-pull-request" className="text-slate-400" />
                );
            }
          };

          return (
            <tr key={item.id} className="text-slate-600">
              <td className="px-4 py-3 whitespace-nowrap w-px">
                <div className="flex items-center">
                  <label className="inline-flex">
                    <span className="sr-only">Select</span>
                    <Checkbox
                      checked={isCheck.includes(item.id) || selectAll}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleClick(item.id, e.target.checked);
                      }}
                    />
                  </label>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <Link
                  to={`/testlibrary/details/${item.id}`}
                  className="font-medium text-sky-500"
                >
                  {item.name}
                </Link>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                {format(new Date(item.date), "yyyy-MM-dd")}
              </td>
              <td className="px-4 py-3 font-medium text-slate-800 whitespace-nowrap">
                {item.department}
              </td>
              <td className="px-4 py-3 whitespace-nowrap">{item.sequence}</td>
              <td className="px-4 py-3 font-medium">{item.form}</td>
              <td className="px-4 py-3 whitespace-nowrap">
                <Badge type={statusType()}>{item.status}</Badge>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <a href="#">{item.location}</a>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center gap-x-2">
                  {typeIcon()}
                  <div>{item.type}</div>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center justify-center gap-x-3">
                  <TooltipV2 content="Edit">
                    <Link to={`/testlibrary/${item.id}`}>
                      <Icon name="edit-3" className="text-slate-500" />
                    </Link>
                  </TooltipV2>
                </div>
              </td>
            </tr>
          );
        }}
      />
    </div>
  );
}

export default TestsTable;
