import { AccountSettingsInputs } from "interfaces/AccountSettings";
import { TestRowType, TestSortType, UserProfile } from "interfaces/Global";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { DatePeriodType } from "type-defs/Global";
import {
  ConfirmPasswordChangeInputs,
  LoginInputs,
  ResetPasswordInputs,
  SignupInputs,
} from "type-defs/Inputs";

import {
  addNewTest,
  addTestsToSchedule,
  confirmPasswordChange,
  deleteTestsFromLibrary,
  getAllAdminUsers,
  getAllTestsApprovalsList,
  getAllTestsLibrary,
  getAllTestsLogs,
  getAllTestsSchedules,
  getSingleTest,
  requestPasswordChange,
  requestPasswordReset,
  signIn,
  signUp,
  updateAccountSettings,
  updateTest,
} from "../services/api";

export const QUERY_KEYS = {
  GET_ME: "profile",
  SIGN_IN: "signin",
  SIGN_UP: "signup",
  ADMIN_USERS: "adminusers",
  RESET_PASSWORD: "resetpassword",
  UPDATE_ACCOUNT_SETTINGS: "updateaccountsettings",
  REQUEST_PASSWORD_CHANGE: "requestpasswordchange",
  ADD_TESTS_TO_SCHEDULE: "addteststoschedule",
  TESTS_LIBRARY: "testslibrary",
  TESTS_LOGS: "testslogs",
  TESTS_SCHEDULES: "testsschedules",
  TESTS_APPROVALS: "testsapprovals",
  DELETE_TESTS_FROM_LIBRARY: "deletetestsfromlibrary",
  ADD_NEW_TEST: "addnewtest",
  UPDATE_TEST: "updatetest",
  GET_SINGLE_TEST: "getsingletest",
};

export const useAdminUsers = (
  currentPage: number,
  selectedDuration: DatePeriodType,
  sortOptions: any
): UseQueryResult<any> => {
  return useQuery(
    [QUERY_KEYS.ADMIN_USERS, currentPage, selectedDuration, sortOptions],
    async () => {
      const data = await getAllAdminUsers();
      return data;
    }
  );
};

export const useTestsLibrary = (
  currentPage: number,
  selectedDuration: DatePeriodType,
  sortOptions: TestSortType
): UseQueryResult<TestRowType[]> => {
  return useQuery(
    [QUERY_KEYS.TESTS_LIBRARY, currentPage, selectedDuration, sortOptions],
    async () => {
      const data = await getAllTestsLibrary();
      return data;
    }
  );
};

export const useTestsLogs = (
  currentPage: number,
  selectedDuration: DatePeriodType,
  sortOptions: TestSortType
): UseQueryResult<TestRowType[]> => {
  return useQuery(
    [QUERY_KEYS.TESTS_LOGS, currentPage, selectedDuration, sortOptions],
    async () => {
      const data = await getAllTestsLogs();
      return data;
    }
  );
};

export const useTestsSchedules = (
  currentPage: number,
  selectedDuration: DatePeriodType,
  sortOptions: TestSortType
): UseQueryResult<TestRowType[]> => {
  return useQuery(
    [QUERY_KEYS.TESTS_SCHEDULES, currentPage, selectedDuration, sortOptions],
    async () => {
      const data = await getAllTestsSchedules();
      return data;
    }
  );
};

export const useTestsApprovalsList = (
  currentPage: number,
  selectedDuration: DatePeriodType,
  sortOptions: TestSortType
): UseQueryResult<TestRowType[]> => {
  return useQuery(
    [QUERY_KEYS.TESTS_APPROVALS, currentPage, selectedDuration, sortOptions],
    async () => {
      const data = await getAllTestsApprovalsList();
      return data;
    }
  );
};

export const useGetSingleTest = (id: number): UseQueryResult<TestRowType> => {
  return useQuery([QUERY_KEYS.GET_SINGLE_TEST, id], async () => {
    const data = await getSingleTest(id);
    return data;
  });
};

export const useSignIn = (): UseMutationResult<UserProfile | null> => {
  return useMutation(QUERY_KEYS.SIGN_IN, async (payload: unknown) => {
    return signIn(payload as LoginInputs);
  });
};

export const useDeleteTestsFromLibrary = (): UseMutationResult<boolean> => {
  return useMutation(
    QUERY_KEYS.DELETE_TESTS_FROM_LIBRARY,
    async (payload: unknown) => {
      return deleteTestsFromLibrary(payload as string[]);
    }
  );
};

export const useUpdateAdminUsers = () => {
  const queryClient = useQueryClient();

  return useMutation(
    QUERY_KEYS.ADMIN_USERS,
    async (payload: unknown) => {
      return payload;
    },
    {
      onSuccess(data: any) {
        queryClient.setQueryData([QUERY_KEYS.ADMIN_USERS, data?.id], data);
      },
    }
  );
};

export const useSignUp = (): UseMutationResult<UserProfile | null> => {
  return useMutation(QUERY_KEYS.SIGN_UP, async (payload: unknown) => {
    return signUp(payload as SignupInputs);
  });
};

export const useRequestResetPassword = (): UseMutationResult<
  boolean | null
> => {
  return useMutation(QUERY_KEYS.RESET_PASSWORD, async (payload: unknown) => {
    return requestPasswordReset(payload as ResetPasswordInputs);
  });
};

export const useConfirmPasswordChange = (): UseMutationResult<
  boolean | null
> => {
  return useMutation(QUERY_KEYS.RESET_PASSWORD, async (payload: unknown) => {
    return confirmPasswordChange(payload as ConfirmPasswordChangeInputs);
  });
};

export const useUpdateAccountSettings =
  (): UseMutationResult<AccountSettingsInputs | null> => {
    return useMutation(
      QUERY_KEYS.UPDATE_ACCOUNT_SETTINGS,
      async (payload: unknown) => {
        return updateAccountSettings(payload as AccountSettingsInputs);
      }
    );
  };

export const useAddNewTest = (): UseMutationResult<boolean> => {
  return useMutation(QUERY_KEYS.ADD_NEW_TEST, async (payload: unknown) => {
    return addNewTest(payload as Partial<TestRowType>);
  });
};

export const useUpdateTest = (): UseMutationResult<boolean> => {
  return useMutation(QUERY_KEYS.UPDATE_TEST, async (payload: unknown) => {
    return updateTest(payload as Partial<TestRowType>);
  });
};

export const useRequestPasswordChange = (): UseMutationResult<
  boolean | null
> => {
  return useMutation(QUERY_KEYS.REQUEST_PASSWORD_CHANGE, async () => {
    return requestPasswordChange();
  });
};

export const useAddTestsToSchedule = (): UseMutationResult<boolean | null> => {
  return useMutation(
    QUERY_KEYS.ADD_TESTS_TO_SCHEDULE,
    async (payload: unknown) => {
      return addTestsToSchedule(payload as string[]);
    }
  );
};
