import classNames from "classnames";
import Icon from "components/Icon";
import React from "react";

type Props = {
  checked: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

const Checkbox = ({ checked, onChange }: Props) => {
  return (
    <div className="relative">
      <input
        type="checkbox"
        className="absolute inset-0 opacity-0 z-10"
        onChange={onChange}
      />
      <div
        className={classNames(
          "h-4 w-4 rounded-sm grid place-items-center",
          checked ? "bg-blue-600" : "bg-white border border-gray-300"
        )}
      >
        {checked && <Icon name="check" size={12} className="text-white" />}
      </div>
    </div>
  );
};

export default Checkbox;
