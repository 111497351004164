import Icon from "components/Icon";
import React, { useMemo } from "react";

interface PaginationProps {
  currentPage: number;
  perPage: number;
  total: number;
  next: () => void;
  prev: () => void;
}

function PaginationClassic({
  next,
  prev,
  total,
  perPage,
  currentPage,
}: PaginationProps) {
  const totalPages = useMemo(
    () => Math.ceil(total / perPage),
    [perPage, total]
  );
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav
        className="mb-4 sm:mb-0 sm:order-1"
        role="navigation"
        aria-label="Navigation"
      >
        <ul className="flex justify-center">
          <li className="ml-3 first:ml-0">
            <button
              onClick={prev}
              className="btn bg-white border-slate-200 hover:border-slate-300 disabled:hover:border-slate-200 text-indigo-500 disabled:cursor-not-allowed disabled:text-slate-200 flex items-center gap-x-2"
              disabled={currentPage < 2}
            >
              <Icon name="arrow-left" /> Previous
            </button>
          </li>
          <li className="ml-3 first:ml-0">
            <button
              onClick={next}
              className="btn bg-white border-slate-200 hover:border-slate-300 disabled:hover:border-slate-200 text-indigo-500 disabled:cursor-not-allowed disabled:text-slate-200 flex items-center gap-x-2"
              disabled={currentPage === totalPages}
            >
              Next <Icon name="arrow-right" />
            </button>
          </li>
        </ul>
      </nav>
      <div className="text-sm text-slate-500 text-center sm:text-left">
        Showing{" "}
        <span className="font-medium text-slate-600">
          {1 + perPage * (currentPage - 1)}
        </span>{" "}
        to{" "}
        <span className="font-medium text-slate-600">
          {perPage * currentPage > total ? total : perPage * currentPage}
        </span>{" "}
        of <span className="font-medium text-slate-600">{total}</span> results
      </div>
    </div>
  );
}

export default PaginationClassic;
