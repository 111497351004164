import classNames from "classnames";
import React, { ReactNode, useState } from "react";

type Props = {
  children: (clickFn: () => void, open: boolean) => ReactNode;
  active: boolean;
};

function SidebarLinkGroup({ children, active }: Props) {
  const [open, setOpen] = useState(active);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <li
      className={classNames("px-3 py-2 rounded-sm", active && "bg-slate-900")}
    >
      {children(handleClick, open)}
    </li>
  );
}

export default SidebarLinkGroup;
