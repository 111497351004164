import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthImage from "assets/images/auth-image.jpg";
import AuthDecoration from "assets/images/auth-decoration.png";
import { useRequestResetPassword } from "hooks/useServerQueries";
import { PageURL } from "type-defs/Global";
import { ResetPasswordInputs } from "type-defs/Inputs";
import LoaderSpinner from "components/LoaderSpinner";
import BeforeLogin from "components/SVGR/BeforeLogin";

function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordInputs>();
  const { mutateAsync, isLoading } = useRequestResetPassword();

  const onSubmit: SubmitHandler<ResetPasswordInputs> = async (
    data: ResetPasswordInputs
  ) => {
    if (data.email) {
      const res = await mutateAsync(data);
      if (res) {
        toast("Password reset link sent! Please check your email", {
          type: "success",
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        toast("An error occoured. Please try again", {
          type: "error",
          autoClose: 5000,
        });
      }
    }
  };
  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to={PageURL.Home}>
                  <BeforeLogin />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto px-4 py-8">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">
                Reset your Password ✨
              </h1>
              {/* Form */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-rose-500">*</span>
                    </label>
                    <input
                      className="form-input w-full"
                      {...register("email", {
                        required: {
                          message: "Email is required",
                          value: true,
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                      })}
                    />
                    {errors?.email?.message && (
                      <p className="mt-1 text-red-500">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="w-32 h-9 btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
                  >
                    <LoaderSpinner loading={isLoading}>
                      Send Reset Link
                    </LoaderSpinner>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
