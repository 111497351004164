import React, { ReactNode } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

type Props = {
  children: ReactNode;
  content: ReactNode;
  [rest: string]: any;
};

const TooltipV2 = ({ children, content, ...rest }: Props) => {
  return (
    <Tippy {...rest} content={content}>
      <button>{children}</button>
    </Tippy>
  );
};

export default TooltipV2;
