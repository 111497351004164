import React from "react";
import { Link, useParams } from "react-router-dom";
import ErrorComponent from "components/Error";
import Loading from "components/Loading";
import TestDetailsPanel from "components/TestDetailsPanel";
import { useGetSingleTest } from "hooks/useServerQueries";

const TestDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetSingleTest(
    id ? parseInt(id, 10) : 0
  );

  if (isLoading || data === undefined) return <Loading />;
  if (isError) return <ErrorComponent />;
  if (!id || data === null) {
    return (
      <p className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        Test Not Found
      </p>
    );
  }

  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <div className="mb-8 flex items-center justify-between max-w-lg">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Test Details &quot;{data?.name || ""}&quot;
          </h1>
          <Link
            className="ml-4 text-lg text-blue-400"
            to={`/testlibrary/${data.id}`}
          >
            Edit
          </Link>
        </div>

        <div className="bg-white shadow-lg rounded-lg mb-8">
          <div className="flex flex-col md:flex-row md:-mr-px">
            <TestDetailsPanel currentTest={data} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default TestDetails;
