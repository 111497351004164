import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Image from "assets/images/user-avatar-80.png";
import { useAddNewTest, useUpdateTest } from "hooks/useServerQueries";
import LoadingSpinner from "components/LoadingSpinner";
import { TestRowType } from "interfaces/Global";
import { PageURL } from "type-defs/Global";

export interface Props {
  currentTest?: TestRowType;
}

function AddTestPanel({ currentTest }: Props) {
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Partial<TestRowType>>();
  const values = watch();
  const navigate = useNavigate();
  const { mutateAsync: addNewTest, isLoading } = useAddNewTest();
  const { mutateAsync: updateTest, isLoading: isUpdateLoading } =
    useUpdateTest();
  const onSubmit: SubmitHandler<Partial<TestRowType>> = async (
    data: Partial<TestRowType>
  ) => {
    const res = currentTest?.id
      ? await updateTest(data)
      : await addNewTest(data);
    if (res) {
      toast(
        currentTest?.id
          ? "Test updated successfully"
          : "Test added to libary successfully",
        {
          type: "success",
          autoClose: 3000,
        }
      );
      navigate(PageURL.TestLibrary);
    } else {
      toast("An error occoured. Please try again", {
        type: "error",
        autoClose: 5000,
      });
    }
  };
  useEffect(() => {
    if (currentTest?.id) {
      reset(currentTest);
    }
  }, [currentTest, reset]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        {/* Picture */}
        <section>
          <div className="flex items-center">
            <div className="mr-4">
              <img
                className="w-40 h-40 rounded-full"
                src={values?.image || Image}
                width="80"
                height="80"
                alt="User upload"
              />
            </div>
            <label htmlFor="image-upload">
              <span
                className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white cursor-pointer"
                aria-hidden="true"
              >
                {currentTest?.id && values.image
                  ? "Update image"
                  : "Add an image"}
              </span>
              <input
                type="file"
                id="image-upload"
                className="hidden"
                accept="image/*"
                onChange={(event) => {
                  if (event?.target?.files?.[0]) {
                    const file = event.target.files?.[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      setValue("image", e?.target?.result?.toString());
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </label>
          </div>
        </section>
        <section>
          <div className="space-y-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Name
              </label>
              <input
                className="form-input w-full"
                {...register("name", {
                  required: {
                    message: "Full Name is required",
                    value: true,
                  },
                })}
              />
              {errors?.name?.message && (
                <p className="mt-1 text-red-500">{errors.name.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Date
              </label>
              <input
                className="form-input w-full"
                type="date"
                {...register("date", {
                  required: {
                    message: "Date is required",
                    value: true,
                  },
                })}
              />
              {errors?.date?.message && (
                <p className="mt-1 text-red-500">{errors.date.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Department
              </label>
              <input
                className="form-input w-full"
                {...register("department", {
                  required: {
                    message: "Department is required",
                    value: true,
                  },
                })}
              />
              {errors?.department?.message && (
                <p className="mt-1 text-red-500">{errors.department.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Batch Type
              </label>
              <select
                className="form-input w-full"
                {...register("batchtype", {
                  required: {
                    message: "Batch Type is required",
                    value: true,
                  },
                })}
              >
                <option value="">Select a Batch Type</option>
                <option value="All">All</option>
                <option value="Single">Single</option>
              </select>
              {errors?.batchtype?.message && (
                <p className="mt-1 text-red-500">{errors.batchtype.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Status
              </label>
              <select
                className="form-input w-full"
                {...register("status", {
                  required: {
                    message: "Status is required",
                    value: true,
                  },
                })}
              >
                <option value="">Select a Status</option>
                <option value="Pending">Pending</option>
                <option value="Warning">Warning</option>
                <option value="Completed">Completed</option>
                <option value="Error">Error</option>
              </select>
              {errors?.status?.message && (
                <p className="mt-1 text-red-500">{errors.status.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Sequence
              </label>
              <input
                className="form-input w-full"
                {...register("sequence", {
                  required: {
                    message: "Sequence is required",
                    value: true,
                  },
                })}
              />
              {errors?.sequence?.message && (
                <p className="mt-1 text-red-500">{errors.sequence.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Location
              </label>
              <input
                className="form-input w-full"
                {...register("location", {
                  required: {
                    message: "Location is required",
                    value: true,
                  },
                })}
              />
              {errors?.location?.message && (
                <p className="mt-1 text-red-500">{errors.location.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Type
              </label>
              <select
                className="form-input w-full"
                {...register("type", {
                  required: {
                    message: "Type is required",
                    value: true,
                  },
                })}
              >
                <option value="">Select a Type</option>
                <option value="Recurring">Recurring</option>
                <option value="One-time">One-time</option>
              </select>
              {errors?.type?.message && (
                <p className="mt-1 text-red-500">{errors.type.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Description
              </label>
              <textarea
                className="form-input w-full"
                {...register("description", {
                  required: {
                    message: "Description is required",
                    value: true,
                  },
                })}
              />
              {errors?.description?.message && (
                <p className="mt-1 text-red-500">
                  {errors.description.message}
                </p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Form
              </label>
              <input
                className="form-input w-full"
                {...register("form", {
                  required: {
                    message: "Form is required",
                    value: true,
                  },
                })}
              />
              {errors?.form?.message && (
                <p className="mt-1 text-red-500">{errors.form.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Start Time
              </label>
              <input
                className="form-input w-full"
                type="time"
                {...register("starttime", {
                  required: {
                    message: "Start Time is required",
                    value: true,
                  },
                })}
              />
              {errors?.starttime?.message && (
                <p className="mt-1 text-red-500">{errors.starttime.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                End Time
              </label>
              <input
                className="form-input w-full"
                type="time"
                {...register("endtime", {
                  required: {
                    message: "End Time is required",
                    value: true,
                  },
                })}
              />
              {errors?.endtime?.message && (
                <p className="mt-1 text-red-500">{errors.endtime.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Result
              </label>
              <select
                className="form-input w-full"
                {...register("pass", {
                  required: {
                    message: "Test Result is required",
                    value: true,
                  },
                })}
              >
                <option value="">Select an Option</option>
                <option value="1">Pass</option>
                <option value="0">Fail</option>
              </select>
              {errors?.pass?.message && (
                <p className="mt-1 text-red-500">{errors.pass.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Details
              </label>
              <textarea
                className="form-input w-full"
                {...register("detail", {
                  required: {
                    message: "Full Name is required",
                    value: true,
                  },
                })}
              />
              {errors?.detail?.message && (
                <p className="mt-1 text-red-500">{errors.detail.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Code
              </label>
              <input
                className="form-input w-full"
                {...register("code", {
                  required: {
                    message: "Code is required",
                    value: true,
                  },
                })}
              />
              {errors?.code?.message && (
                <p className="mt-1 text-red-500">{errors.code.message}</p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Approval Status
              </label>
              <select
                className="form-input w-full"
                {...register("approvalStatus", {
                  required: {
                    message: "Full Name is required",
                    value: true,
                  },
                })}
              >
                <option value="">Select an Approval Status</option>
                <option value="Reported">Reported</option>
                <option value="In Review">In Review</option>
                <option value="Error">Error</option>
              </select>
              {errors?.approvalStatus?.message && (
                <p className="mt-1 text-red-500">
                  {errors.approvalStatus.message}
                </p>
              )}
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Assigned User
              </label>
              <select
                className="form-input w-full"
                {...register("approvalStatus", {
                  required: {
                    message: "Full Name is required",
                    value: true,
                  },
                })}
              >
                <option value="">Assign Users</option>
                <option value="john-doe">John Doe</option>
                <option value="sarah-doe">Sarah Doe</option>
                <option value="angel-doe">Angel Doe</option>
              </select>
              {errors?.approvalStatus?.message && (
                <p className="mt-1 text-red-500">
                  {errors.approvalStatus.message}
                </p>
              )}
            </div>
            {currentTest?.id && (
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="attachments"
                >
                  Attachments
                </label>
                <input
                  className="form-input w-full"
                  {...register("attachments")}
                  type="file"
                  multiple
                />
              </div>
            )}
          </div>
        </section>
      </div>
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-slate-200">
          <div className="flex self-end">
            <button
              type="button"
              className="btn border-slate-200 h-9 hover:border-slate-300 text-slate-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading || isUpdateLoading}
              className="btn bg-indigo-500 w-32 h-9 hover:bg-indigo-600 text-white ml-3"
            >
              {isLoading || isUpdateLoading ? (
                <LoadingSpinner />
              ) : currentTest?.id ? (
                "Update Test"
              ) : (
                "Add Test"
              )}
            </button>
          </div>
        </div>
      </footer>
    </form>
  );
}

export default AddTestPanel;
