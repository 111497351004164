import React from "react";
import { Loading as LoadingDots } from "react-loading-dot";

export interface Props {
  color?: string;
  size?: string;
}

const Loading = ({ color = "#6366f1", size = "1.5rem" }: Props) => {
  return <LoadingDots background={color} size={size} />;
};

export default Loading;
