import { UserProfile } from "interfaces/Global";
import { createDelay } from "./api";

export const getProfile = async (): Promise<UserProfile | null> => {
  try {
    await createDelay();
    const user = localStorage.getItem("user");

    if (user) {
      return JSON.parse(user) as UserProfile;
    }

    return null;
  } catch (error: any) {
    throw Error(error);
  }
};
