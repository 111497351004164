import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

type Props = {
  inputProps: any;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  label?: string;
};

const Input = ({ label, inputProps, error }: Props) => {
  return (
    <div>
      {label && (
        <label htmlFor={inputProps?.name} className="block mb-1">
          {label}
        </label>
      )}
      <input
        {...inputProps}
        className="border rounded-md h-10 w-full focus:border-gray-400 outline-none px-2 text-sm"
      />
      {error?.message && (
        // @ts-ignore
        <p className="text-xs text-red-500 mt-2">{error?.message}</p>
      )}
    </div>
  );
};

export default Input;
