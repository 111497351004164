import React, { useState } from "react";
import { toast } from "react-toastify";
import FilterButton from "components/DropdownFilter";
import DeleteButton from "components/DeleteButton";
import DateSelect from "components/DateSelect";
import TestsTable from "components/TestsTable";
import PaginationClassic from "components/PaginationClassic";
import {
  useAddTestsToSchedule,
  useDeleteTestsFromLibrary,
  useTestsLibrary,
} from "hooks/useServerQueries";
import Loading from "components/Loading";
import ErrorComponent from "components/Error";
import { DatePeriodType } from "type-defs/Global";
import { TestSortType } from "interfaces/Global";
import LoadingSpinner from "components/LoadingSpinner";
import TestApprovalForm from "components/TestsApprovalsTable/TestApprovalForm";
import Icon from "components/Icon";

const DashboardTestLibrary = () => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedDuration, setSelectedDuration] =
    useState<DatePeriodType>("All Time");
  const [selectedSortOptions, setSelectedSortOptions] = useState<TestSortType>({
    batchType: false,
    date: false,
    name: false,
    recurring: false,
    result: false,
    status: false,
  });
  const { data, isLoading, isError, refetch } = useTestsLibrary(
    currentPage,
    selectedDuration,
    selectedSortOptions
  );
  const { mutateAsync, isLoading: isDeleting } = useDeleteTestsFromLibrary();
  const { mutateAsync: addTestsToSchedule, isLoading: isAddingToSchedule } =
    useAddTestsToSchedule();

  const handleSelectedItems = (_selectedItems: number[]) => {
    if (_selectedItems.length) {
      setSelectedItems([..._selectedItems]);
    } else {
      setSelectedItems([]);
    }
  };
  const handleAddToScheduleClick = async () => {
    const res = await addTestsToSchedule(selectedItems);
    if (res) {
      toast("Successfully added selected tests to background schedule", {
        type: "success",
        autoClose: 4000,
      });
    }
  };
  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
              Test Library ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Delete button */}
            <DeleteButton
              selectedItems={selectedItems}
              isDeleting={isDeleting}
              refetch={refetch}
              mutateAsync={mutateAsync}
              handleSelectedItems={handleSelectedItems}
            />
            {/* Dropdown */}
            <DateSelect
              onChange={(period: DatePeriodType) => setSelectedDuration(period)}
            />
            {/* Filter button */}
            <FilterButton
              align="right"
              onChange={(options) => setSelectedSortOptions(options)}
            />
            {selectedItems?.length ? (
              <button
                onClick={() => handleAddToScheduleClick()}
                disabled={isDeleting || isAddingToSchedule}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white w-64"
              >
                {isAddingToSchedule ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <svg
                      className="w-4 h-4 fill-current opacity-50 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">
                      Add to background schedule
                    </span>
                  </>
                )}
              </button>
            ) : (
              <TestApprovalForm>
                {(setIsOpen) => (
                  <button
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <Icon name="plus" />
                    <span className="hidden xs:block ml-2">Add Test</span>
                  </button>
                )}
              </TestApprovalForm>
            )}
          </div>
        </div>
        {isLoading ? <Loading /> : null}
        {isError ? <ErrorComponent /> : null}
        {isLoading || isError || !data?.length ? null : (
          <>
            {/* Table */}
            <TestsTable
              selectedItems={handleSelectedItems}
              items={data}
              currentPage={currentPage}
              perPage={10}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                currentPage={currentPage}
                next={() => {
                  setCurrentPage((prev) => prev + 1);
                }}
                prev={() => {
                  setCurrentPage((prev) => prev - 1);
                }}
                perPage={10}
                total={data.length}
              />
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default DashboardTestLibrary;
