import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(public props: Props, public state: State) {
    super(props, state);

    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error("Error Boundary:", error, errorInfo.componentStack);
  }

  public render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <p className="pt-5 px-5 text-red-600">
          Internal error. Refresh the page and try again.
        </p>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
