import Modal from "components/Modal";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import React, { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

type Props = {
  id: number | null;
  name: string;
  children(setIsOpen: any): ReactNode;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

const ApproveModal = ({ id, name, children }: Props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, reset } = useForm({
    resolver,
    defaultValues: {
      id,
      name,
    },
  });
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data: any) => {
    console.log(data);
    reset();
    setIsOpen(false);
  };

  return (
    <>
      {children(setIsOpen)}
      <Modal modalOpen={isOpen} setModalOpen={setIsOpen}>
        <div className="w-[30rem] p-8">
          <p className="whitespace-normal font-medium text-lg mb-4">
            Are you sure you want to approve{" "}
            <span className="font-bold">{name}</span>?
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-y-4"
          >
            <div className="flex items-center justify-center gap-x-3">
              <button
                type="button"
                className="px-4 bg-gray-200 h-10 hover:bg-gray-300 rounded-md"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 bg-indigo-500 h-10 hover:bg-indigo-600 text-white rounded-md"
              >
                Approve
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ApproveModal;
