import classNames from "classnames";
import React, { ReactNode } from "react";
import "components/Table/Table.scss";

type Props = {
  selectAllHeader?: ReactNode;
  headers: any[];
  data: any[];
  renderItem(item: any): ReactNode;
};

const Table = ({ selectAllHeader, headers, data, renderItem }: Props) => {
  return (
    <div className="ait-table overflow-x-auto">
      <table className="table-auto w-full divide-y divide-slate-200">
        <thead className="text-xs uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
          <tr>
            {selectAllHeader && (
              <th className="px-4 py-3 whitespace-nowrap w-px">
                {selectAllHeader}
              </th>
            )}
            {headers.map((header: string) => (
              <th
                className={classNames(
                  "px-4 py-3 whitespace-nowrap",
                  header === "" && "w-px"
                )}
                key={header}
              >
                <div className="font-semibold text-left">{header}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-sm">
          {data?.map((item) => renderItem(item))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
