import React from "react";
import Image from "assets/images/user-avatar-80.png";
import { TestRowType } from "interfaces/Global";

export interface Props {
  currentTest: TestRowType;
}

function TestDetailsPanel({ currentTest }: Props) {
  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        {/* Picture */}
        <section>
          <div className="flex items-center w-40 h-40 rounded-full bg-gray-200">
            <img
              className="w-40 h-40 rounded-full"
              src={currentTest.image || Image}
              width="80"
              height="80"
              alt="User upload"
            />
          </div>
        </section>
        <section>
          <div className="space-y-4 mt-5 flex flex-col gap-y-2">
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Name:
              </label>
              <p className="block font-light">{currentTest.name}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Date:
              </label>
              <p className="block font-light">{currentTest.date}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Department:
              </label>
              <p className="block font-light">{currentTest.department}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Batch Type:
              </label>
              <p className="block font-light">{currentTest.batchtype}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Status:
              </label>
              <p className="block font-light">{currentTest.status}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Sequence:
              </label>
              <p className="block font-light">{currentTest.sequence}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Location:
              </label>
              <p className="block font-light">{currentTest.location}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Type:
              </label>
              <p className="block font-light">{currentTest.type}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Description:
              </label>
              <p className="block font-light">{currentTest.description}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Form:
              </label>
              <p className="block font-light">{currentTest.form}</p>
            </div>
            <div className="flex gap-x-4">
              <div className="flex items-center gap-x-2 text-lg">
                <label className="block font-medium" htmlFor="name">
                  Start Time:
                </label>
                <p className="block font-light">{currentTest.starttime}</p>
              </div>
              <div className="flex items-center gap-x-2 text-lg">
                <label className="block font-medium" htmlFor="name">
                  End Time:
                </label>
                <p className="block font-light">{currentTest.endtime}</p>
              </div>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Result:
              </label>
              <p className="block font-light">
                {currentTest.pass === 0 ? "Fail" : "Pass"}
              </p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Details:
              </label>
              <p className="block font-light">{currentTest.detail}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Code:
              </label>
              <p className="block font-light">{currentTest.code}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Approval Status:
              </label>
              <p className="block font-light">{currentTest.approvalStatus}</p>
            </div>
            <div className="flex items-center gap-x-2 text-lg">
              <label className="block font-medium" htmlFor="name">
                Attachments:
              </label>
              <p className="block font-light">
                {/* @TODO: MV */}
                Any attachements will appear here
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TestDetailsPanel;
