import LoadingSpinner from "components/LoadingSpinner";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  loading?: boolean;
};

const LoaderSpinner = ({ children, loading = false }: Props) => {
  if (loading) {
    return <LoadingSpinner />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default LoaderSpinner;
