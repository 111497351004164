import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import Image from "assets/images/user-avatar-80.png";
import { useUpdateAccountSettings } from "hooks/useServerQueries";
import { useUserContext } from "contexts/UserContext";
import { AccountSettingsInputs } from "interfaces/AccountSettings";
import { UserProfile } from "interfaces/Global";
import { UserRole } from "type-defs/Global";
import LoaderSpinner from "components/LoaderSpinner";
import ChangePasswordModal from "./ChangePasswordModal";

function AccountPanel() {
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AccountSettingsInputs>();
  const [isNewPasswordModalOpen, setIsNewPasswordModalOpen] =
    useState<boolean>(false);
  const { user, setUser } = useUserContext();
  const values = watch();
  const { mutateAsync, isLoading } = useUpdateAccountSettings();
  const onSubmit: SubmitHandler<AccountSettingsInputs> = async (
    data: AccountSettingsInputs
  ) => {
    const res = await mutateAsync(data);
    if (res?.fullName && user?.fullName) {
      const userPayload: UserProfile = {
        fullName: res?.fullName || user.fullName,
        profile: res?.profile || user?.profile,
        email: res?.profile || user.email,
        role: user?.role || UserRole.Guest,
        portalURLAddress: res?.portalURLAddress || data.portalURLAddress,
        company: {
          id: 1,
          name: "Ino",
        },
      };
      setUser(userPayload);
      localStorage.setItem("user", JSON.stringify(userPayload));
      toast("Account settings updated", {
        type: "success",
        autoClose: 3000,
      });
    } else {
      toast("An error occoured. Please try again", {
        type: "error",
        autoClose: 5000,
      });
    }
  };
  useEffect(() => {
    if (user?.email) {
      reset({
        fullName: user.fullName,
        profile: user?.profile,
        portalURLAddress: user?.portalURLAddress,
      });
    }
  }, [user, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="grow">
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 font-bold mb-5">My Account</h2>
          {/* Picture */}
          <section>
            <div className="flex items-center">
              <div className="mr-4">
                <img
                  className="w-40 h-40 rounded-full"
                  src={values?.profile || Image}
                  width="80"
                  height="80"
                  alt="User upload"
                />
              </div>
              <label htmlFor="image-upload">
                <span
                  className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white cursor-pointer"
                  aria-hidden="true"
                >
                  Change
                </span>
                <input
                  type="file"
                  id="image-upload"
                  className="hidden"
                  accept="image/*"
                  onChange={(event) => {
                    if (event?.target?.files?.[0]) {
                      const file = event.target.files?.[0];
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        setValue("profile", e?.target?.result?.toString());
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
              </label>
            </div>
          </section>
          {/* Business Profile */}
          <section>
            <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
              Business Profile
            </h2>
            <div className="text-sm">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit.
            </div>
            <div className="space-y-4 mt-5">
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  Account Name
                </label>
                <input
                  className="form-input w-full"
                  {...register("fullName", {
                    required: {
                      message: "Full Name is required",
                      value: true,
                    },
                  })}
                />
                {errors?.fullName?.message && (
                  <p className="mt-1 text-red-500">{errors.fullName.message}</p>
                )}
              </div>
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="business-id"
                >
                  Portal URL Address
                </label>
                <input
                  className="form-input w-full"
                  {...register("portalURLAddress", {
                    required: {
                      message: "Portal URL Address is required",
                      value: true,
                    },
                  })}
                />
                {errors?.portalURLAddress?.message && (
                  <p className="mt-1 text-red-500">
                    {errors.portalURLAddress.message}
                  </p>
                )}
              </div>
              <div className="sm:w-1/3">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="location"
                >
                  Admin Email Account
                </label>
                <input
                  className="form-input w-full"
                  type="email"
                  disabled
                  defaultValue={user?.email}
                />
              </div>
            </div>
          </section>
          {/* Password */}
          <section>
            <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">
              Password
            </h2>
            <div className="mt-5">
              <button
                type="button"
                onClick={() => {
                  setIsNewPasswordModalOpen(true);
                }}
                className="btn border-slate-200 shadow-sm text-indigo-500 w-40"
              >
                Set New Password
              </button>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200">
            <div className="flex self-end">
              <button
                type="button"
                className="btn border-slate-200 h-9 hover:border-slate-300 text-slate-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className="btn bg-indigo-500 w-32 h-9 hover:bg-indigo-600 text-white ml-3"
              >
                <LoaderSpinner loading={isLoading}>Save Changes</LoaderSpinner>
              </button>
            </div>
          </div>
        </footer>
      </form>
      <ChangePasswordModal
        isOpen={isNewPasswordModalOpen}
        setIsOpen={setIsNewPasswordModalOpen}
      />
    </>
  );
}

export default AccountPanel;
