import Icon from "components/Icon";
import Modal from "components/Modal";
import React, { useState } from "react";

type Props = {
  name?: string;
};

const DeleteTeamModal = ({ name }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Icon name="trash" />
      </button>
      <Modal modalOpen={isOpen} setModalOpen={setIsOpen}>
        <div className="w-[30rem] p-8">
          <p className="whitespace-normal text-center font-medium text-lg mb-8">
            Are you sure you want to delete{" "}
            <span className="font-extrabold">{name}</span>? This action is not
            undoable.
          </p>
          <form onSubmit={onSubmit} className="flex flex-col gap-y-4">
            <input
              type="text"
              className="outline-none rounded-md h-10 border-none bg-gray-100 text-sm"
              placeholder="Enter the team name to confirm"
            />
            <button
              type="submit"
              className="bg-red-500 h-10 hover:bg-red-600 text-white rounded-md"
            >
              Confirm Deletion
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default DeleteTeamModal;
