import { AccountSettingsInputs } from "interfaces/AccountSettings";
import { TestRowType, UserProfile } from "interfaces/Global";
import { allTests } from "mocks/tests.mock";
import allUsers from "mocks/users.mock";
import { UserRole } from "type-defs/Global";
import {
  ConfirmPasswordChangeInputs,
  LoginInputs,
  ResetPasswordInputs,
  SignupInputs,
} from "type-defs/Inputs";

export const createDelay = async (timeout?: number) => {
  await new Promise((resolve) => {
    setTimeout(resolve, timeout || 1000);
  });
};

export const signIn = async (
  payload: LoginInputs
): Promise<UserProfile | null> => {
  try {
    await createDelay();
    if (payload?.email) {
      return {
        email: payload.email,
        fullName: payload.email.split("@")[0],
        profile: "",
        role: UserRole.CustomerUser,
        company: {
          id: 1,
          name: "Ino",
        },
      };
    }
    return null;
  } catch (error: any) {
    throw Error(error);
  }
};

export const deleteTestsFromLibrary = async (
  payload: string[]
): Promise<boolean> => {
  try {
    await createDelay();
    if (payload?.length) {
      return true;
    }
    return false;
  } catch (error: any) {
    throw Error(error);
  }
};

export const signUp = async (
  payload: SignupInputs
): Promise<UserProfile | null> => {
  try {
    await createDelay();
    if (payload?.email) {
      return {
        email: payload.email,
        fullName: payload.fullName,
        profile: "",
        role: UserRole.CustomerUser,
        company: {
          id: 1,
          name: "Ino",
        },
      };
    }
    return null;
  } catch (error: any) {
    throw Error(error);
  }
};

export const requestPasswordReset = async (
  payload: ResetPasswordInputs
): Promise<boolean | null> => {
  try {
    await createDelay();
    if (payload?.email) {
      return true;
    }
    return false;
  } catch (error: any) {
    throw Error(error);
  }
};

export const confirmPasswordChange = async (
  payload: ConfirmPasswordChangeInputs
): Promise<boolean | null> => {
  try {
    await createDelay();
    if (payload?.password) {
      return true;
    }
    return false;
  } catch (error: any) {
    throw Error(error);
  }
};

export const updateAccountSettings = async (
  payload: AccountSettingsInputs
): Promise<AccountSettingsInputs | null> => {
  try {
    await createDelay();
    return payload?.fullName ? payload : null;
  } catch (error: any) {
    throw Error(error);
  }
};

export const addNewTest = async (
  payload: Partial<TestRowType>
): Promise<boolean> => {
  try {
    await createDelay();
    return !!payload?.name;
  } catch (error: any) {
    throw Error(error);
  }
};

export const updateTest = async (
  payload: Partial<TestRowType>
): Promise<boolean> => {
  try {
    await createDelay();
    return !!payload?.id;
  } catch (error: any) {
    throw Error(error);
  }
};

export const requestPasswordChange = async (): Promise<boolean | null> => {
  try {
    await createDelay();
    return true;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getAllAdminUsers = async (): Promise<any> => {
  try {
    await createDelay();
    return allUsers;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getAllTestsLibrary = async (): Promise<TestRowType[]> => {
  try {
    await createDelay();
    return allTests;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getAllTestsLogs = async (): Promise<TestRowType[]> => {
  try {
    await createDelay();
    return allTests;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getAllTestsSchedules = async (): Promise<TestRowType[]> => {
  try {
    await createDelay();
    return allTests;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getAllTestsApprovalsList = async (): Promise<TestRowType[]> => {
  try {
    await createDelay();
    return allTests;
  } catch (error: any) {
    throw Error(error);
  }
};

export const addTestsToSchedule = async (
  payload: string[]
): Promise<boolean | null> => {
  try {
    await createDelay();
    return !!payload.length;
  } catch (error: any) {
    throw Error(error);
  }
};

export const getSingleTest = async (
  id: number
): Promise<TestRowType | null> => {
  try {
    await createDelay();
    const selected = allTests.filter((test) => test.id === id)?.[0];
    if (selected) {
      return selected;
    }
    return null;
  } catch (error: any) {
    throw Error(error);
  }
};
