import Icon from "components/Icon";
import { TestSortType } from "interfaces/Global";
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import Transition from "utils/Transition";

export interface Props {
  align: "right" | "left";
  onChange: (options: TestSortType) => void;
}

function DropdownFilter({ align, onChange }: Props) {
  const { register, handleSubmit, reset } = useForm<TestSortType>();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLFormElement>(null);

  // close on click outside
  useEffect(() => {
    // @ts-ignore
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current?.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  const onSubmit = (data: TestSortType) => {
    onChange(data);
    setDropdownOpen(false);
  };

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="btn bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Filter</span>
        <wbr />
        <Icon name="sliders" />
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`origin-top-right z-10 absolute top-full min-w-56 bg-white border border-slate-200 pt-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        appear
      >
        <form onSubmit={handleSubmit(onSubmit)} ref={dropdown}>
          <div className="text-xs font-semibold text-slate-400 uppercase pt-1.5 pb-2 px-4">
            Filters
          </div>
          <ul className="mb-4">
            <li className="py-1 px-3">
              <label className="flex items-center">
                <input
                  {...register("name")}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="text-sm font-medium ml-2">Name</span>
              </label>
            </li>
            <li className="py-1 px-3">
              <label className="flex items-center">
                <input
                  {...register("date")}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="text-sm font-medium ml-2">Date</span>
              </label>
            </li>
            <li className="py-1 px-3">
              <label className="flex items-center">
                <input
                  {...register("status")}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="text-sm font-medium ml-2">Status</span>
              </label>
            </li>
            <li className="py-1 px-3">
              <label className="flex items-center">
                <input
                  {...register("batchType")}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="text-sm font-medium ml-2">Batch Type</span>
              </label>
            </li>
            <li className="py-1 px-3">
              <label className="flex items-center">
                <input
                  {...register("recurring")}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="text-sm font-medium ml-2">Recurring</span>
              </label>
            </li>
            <li className="py-1 px-3">
              <label className="flex items-center">
                <input
                  {...register("result")}
                  type="checkbox"
                  className="form-checkbox"
                />
                <span className="text-sm font-medium ml-2">Result</span>
              </label>
            </li>
          </ul>
          <div className="py-2 px-3 border-t border-slate-200 bg-slate-50">
            <ul className="flex items-center justify-between">
              <li>
                <button
                  type="button"
                  onClick={() => reset()}
                  className="btn-xs bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
                >
                  Clear
                </button>
              </li>
              <li>
                <button
                  type="submit"
                  className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  Apply
                </button>
              </li>
            </ul>
          </div>
        </form>
      </Transition>
    </div>
  );
}

export default DropdownFilter;
