import React, { useState, useEffect, useMemo } from "react";
import { TestRowType } from "interfaces/Global";
import Checkbox from "components/Checkbox";
import Table from "components/Table/Table";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Badge from "components/Badge";
import { ApprovalTestStatus } from "type-defs/Global";
import TooltipV2 from "components/TooltipV2/TooltipV2";
import TableHeader from "components/TableHeader/TableHeader";
import ApproveModal from "./ApproveModal";
import TestApprovalForm from "./TestApprovalForm";

export interface Props {
  items: TestRowType[];
  selectedItems: (_selectedItems: number[]) => void;
  perPage: number;
  currentPage: number;
}

function TestsApprovalsTable({
  selectedItems,
  items,
  perPage,
  currentPage,
}: Props) {
  const paginationItems = useMemo(() => {
    const obegone = items.slice(
      perPage * (currentPage - 1),
      perPage * currentPage
    );
    return obegone;
  }, [items, perPage, currentPage]);
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(paginationItems.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id: number, checked: boolean) => {
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const statusType = (status: ApprovalTestStatus) => {
    switch (status) {
      case "Reported":
        return "success";
      case "In Review":
        return "warning";
      case "Error":
        return "danger";
      default:
        return "default";
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg relative">
      <TableHeader withSearch>
        <h2 className="font-semibold text-slate-800">
          All Tests{" "}
          <span className="text-slate-400 font-medium">{items.length}</span>
        </h2>
      </TableHeader>
      <Table
        headers={[
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select all</span>
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
            </label>
          </div>,
          "Name",
          "Date",
          "Error Code",
          "Status",
          <div className="text-center">Actions</div>,
        ]}
        data={paginationItems}
        renderItem={(item) => {
          return (
            <tr key={item.id} className="text-slate-600">
              <td className="px-4 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="flex items-center">
                  <label className="inline-flex">
                    <span className="sr-only">Select</span>
                    <Checkbox
                      checked={isCheck.includes(item.id) || selectAll}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleClick(item.id, e.target.checked);
                      }}
                    />
                  </label>
                </div>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <Link
                  to={`/testlibrary/details/${item.id}`}
                  className="font-medium text-sky-500"
                >
                  {item.name}
                </Link>
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                {format(new Date(item.date), "yyyy-MM-dd")}
              </td>
              <td className="px-4 py-3 whitespace-nowrap">{item.code}</td>
              <td className="px-4 py-3 whitespace-nowrap">
                <Badge type={statusType(item.approvalStatus)}>
                  {item.approvalStatus}
                </Badge>
              </td>
              <td className="px-4 w-px">
                <div className="flex items-center justify-center gap-x-3">
                  <TooltipV2 content="Edit">
                    <TestApprovalForm
                      id={item.id}
                      name={item.name}
                      date={item.date}
                      status={item.approvalStatus}
                      department={item.department}
                    >
                      {(setIsOpen) => (
                        <button
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          <Icon name="edit-3" />
                        </button>
                      )}
                    </TestApprovalForm>
                  </TooltipV2>
                  <TooltipV2 content="Approve">
                    <ApproveModal id={item.id} name={item.name}>
                      {(setIsOpen) => (
                        <button
                          className="p-1.5"
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          <Icon name="check" />
                        </button>
                      )}
                    </ApproveModal>
                  </TooltipV2>
                </div>
              </td>
            </tr>
          );
        }}
      />
    </div>
  );
}

export default TestsApprovalsTable;
