import Input from "components/Input";
import Modal from "components/Modal";
import Select from "components/Select";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import React, { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

type Props = {
  id?: number | null;
  name?: string;
  type?: string;
  children(setIsOpen: any): ReactNode;
};

const validationSchema = Yup.object({
  name: Yup.string()
    .when("type", {
      is: "domain",
      then: Yup.string()
        .url("Must enter a valid URL")
        .required("URL is required"),
    })
    .when("type", {
      is: "team",
      then: Yup.string().required("Name is required"),
    }),
  type: Yup.string().required("Type is required"),
});

const WhitelistForm = ({
  id = null,
  name = "",
  type = "domain",
  children,
}: Props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: {
      id,
      name,
      type,
    },
  });
  const isEditing = !!(id && name && type);
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data: any) => {
    console.log(data);
    reset();
    setIsOpen(false);
  };

  return (
    <>
      {children(setIsOpen)}
      <Modal modalOpen={isOpen} setModalOpen={setIsOpen}>
        <div className="w-[30rem] p-8">
          <p className="whitespace-normal font-medium text-lg mb-4">
            {isEditing ? `Edit ${type}` : "Create Customer/Domain"}
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-y-4"
          >
            <Select
              inputProps={register("type")}
              label="Type"
              options={[
                {
                  label: "Domain",
                  value: "domain",
                },
                {
                  label: "Team",
                  value: "team",
                },
              ]}
            />
            <Input
              inputProps={register("name")}
              label="Name"
              error={errors.name}
            />
            <button
              type="submit"
              className="bg-indigo-500 h-10 hover:bg-indigo-600 text-white rounded-md"
            >
              {isEditing ? "Save changes" : "Create"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default WhitelistForm;
