import Icon from "components/Icon";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  withSearch?: boolean;
};

const TableHeader = ({ children, withSearch = false }: Props) => {
  return (
    <div className="flex items-center justify-between h-14 pl-5 pr-2">
      <header className="whitespace-nowrap">{children}</header>
      {withSearch && (
        <div className="flex items-center h-full relative py-2 w-64">
          <Icon
            name="search"
            className="absolute top-1/2 -translate-y-1/2 left-2"
          />
          <input
            type="text"
            className="shadow-none border border-gray-200 bg-gray-200 !outline-none w-full rounded-lg pl-7 pr-4 h-full text-sm"
            placeholder="Search here..."
          />
        </div>
      )}
    </div>
  );
};

export default TableHeader;
