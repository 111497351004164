import React, { useState } from "react";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import Modal from "components/Modal";
import LoaderSpinner from "components/LoaderSpinner";

export interface Props {
  selectedItems: any[];
  isDeleting: boolean;
  refetch: () => void;
  mutateAsync: UseMutateAsyncFunction<boolean, unknown, unknown, unknown>;
  handleSelectedItems: (_selectedItems: any[]) => void;
}

function DeleteButton({
  selectedItems,
  isDeleting,
  mutateAsync,
  refetch,
  handleSelectedItems,
}: Props) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onDelete = async () => {
    await mutateAsync(selectedItems);
    refetch();
    toast("Successfully deleted selected items", {
      type: "success",
      autoClose: 4000,
    });
    handleSelectedItems([]);
    setIsDeleteModalOpen(false);
  };
  return (
    <div className={`${selectedItems.length < 1 && "hidden"}`}>
      {isDeleteModalOpen && (
        <Modal
          modalOpen={isDeleteModalOpen}
          setModalOpen={setIsDeleteModalOpen}
        >
          <div className="w-full flex py-4 justify-center items-center flex-col">
            <p>
              Are you sure you want to delete {selectedItems.length} item(s)?
            </p>
            <div className="flex items-center justify-center w-full mt-4 space-x-4">
              <button
                onClick={() => onDelete()}
                disabled={isDeleting}
                className="btn w-36 bg-white border-slate-200 hover:border-slate-300 text-rose-500 hover:text-rose-600"
              >
                <LoaderSpinner loading={isDeleting}>
                  Confirm Delete
                </LoaderSpinner>
              </button>
              <button
                disabled={isDeleting}
                onClick={() => setIsDeleteModalOpen(false)}
                className="btn bg-white border-slate-200 hover:border-slate-300 text-blue-500 hover:text-blue-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className="flex items-center">
        <div className="hidden xl:block text-sm italic mr-2 whitespace-nowrap">
          <span>{selectedItems.length}</span> items selected
        </div>
        <button
          onClick={() => setIsDeleteModalOpen(true)}
          className="btn bg-white border-slate-200 hover:border-slate-300 text-rose-500 hover:text-rose-600"
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default DeleteButton;
