import React, { useState } from "react";
import DeleteButton from "components/DeleteButton";
import DateSelect from "components/DateSelect";
import FilterButton from "components/DropdownFilter";
import TestsApprovalsTable from "components/TestsApprovalsTable";
import PaginationClassic from "components/PaginationClassic";
import {
  useDeleteTestsFromLibrary,
  useTestsApprovalsList,
} from "hooks/useServerQueries";
import Loading from "components/Loading";
import ErrorComponent from "components/Error";
import { DatePeriodType } from "type-defs/Global";
import { TestSortType } from "interfaces/Global";
import Icon from "components/Icon";
import TestApprovalForm from "components/TestsApprovalsTable/TestApprovalForm";

function TestApprovals() {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDuration, setSelectedDuration] =
    useState<DatePeriodType>("All Time");

  const [selectedSortOptions, setSelectedSortOptions] = useState<TestSortType>({
    batchType: false,
    date: false,
    name: false,
    recurring: false,
    result: false,
    status: false,
  });

  const { data, isLoading, isError, refetch } = useTestsApprovalsList(
    currentPage,
    selectedDuration,
    selectedSortOptions
  );
  const { mutateAsync, isLoading: isDeleting } = useDeleteTestsFromLibrary();

  const handleSelectedItems = (_selectedItems: number[]) => {
    setSelectedItems([..._selectedItems]);
  };

  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
              Approvals ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Delete button */}
            <DeleteButton
              selectedItems={selectedItems}
              isDeleting={isDeleting}
              refetch={refetch}
              mutateAsync={mutateAsync}
              handleSelectedItems={handleSelectedItems}
            />
            {/* Dropdown */}
            <DateSelect onChange={(period) => setSelectedDuration(period)} />
            {/* Filter button */}
            <FilterButton
              align="right"
              onChange={(options) => setSelectedSortOptions(options)}
            />
            {/* Add order button */}
            <TestApprovalForm>
              {(setIsOpen) => (
                <button
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <Icon name="plus" />
                  <span className="hidden xs:block ml-2">Add Test</span>
                </button>
              )}
            </TestApprovalForm>
          </div>
        </div>
        {isLoading ? <Loading /> : null}
        {isError ? <ErrorComponent /> : null}
        {isLoading || isError || !data?.length ? null : (
          <>
            {/* Table */}
            <TestsApprovalsTable
              selectedItems={handleSelectedItems}
              items={data}
              currentPage={currentPage}
              perPage={10}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                currentPage={currentPage}
                next={() => {
                  setCurrentPage((prev) => prev + 1);
                }}
                prev={() => {
                  setCurrentPage((prev) => prev - 1);
                }}
                perPage={10}
                total={data.length}
              />
            </div>
          </>
        )}
      </div>
    </main>
  );
}

export default TestApprovals;
