import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthImage from "assets/images/auth-image.jpg";
import AuthDecoration from "assets/images/auth-decoration.png";
import { useConfirmPasswordChange } from "hooks/useServerQueries";
import { PageURL } from "type-defs/Global";
import { ConfirmPasswordChangeInputs } from "type-defs/Inputs";
import LoaderSpinner from "components/LoaderSpinner";
import BeforeLogin from "components/SVGR/BeforeLogin";

function ConfirmPasswordChange() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ConfirmPasswordChangeInputs>();
  const { mutateAsync, isLoading, isSuccess } = useConfirmPasswordChange();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ConfirmPasswordChangeInputs> = async (
    data: ConfirmPasswordChangeInputs
  ) => {
    if (data.password && data.confirmPassword) {
      const res = await mutateAsync(data);
      if (res) {
        toast(
          "Password reset successfully. Please login using your new password",
          {
            type: "success",
            position: "top-right",
            autoClose: 5000,
          }
        );
        navigate(PageURL.Home);
      } else {
        toast("An error occoured. Please try again", {
          type: "error",
          autoClose: 5000,
        });
      }
    }
  };
  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to={PageURL.Home}>
                  <BeforeLogin />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto px-4 py-8">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">
                Confirm Password Change ✨
              </h1>
              {/* Form */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      New Password <span className="text-rose-500">*</span>
                    </label>
                    <input
                      className="form-input w-full"
                      type="password"
                      {...register("password", {
                        required: {
                          message: "Password is required",
                          value: true,
                        },
                      })}
                    />
                    {errors?.password?.message && (
                      <p className="mt-1 text-red-500">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Confirm Password <span className="text-rose-500">*</span>
                    </label>
                    <input
                      className="form-input w-full"
                      type="password"
                      {...register("confirmPassword", {
                        required: {
                          message: "Please enter password again",
                          value: true,
                        },

                        validate: (val: string) => {
                          if (watch("password") !== val) {
                            return "Passwords do not match";
                          }
                          return undefined;
                        },
                      })}
                    />
                    {errors?.confirmPassword?.message && (
                      <p className="mt-1 text-red-500">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    disabled={isLoading || isSuccess}
                    className="w-32 h-9 btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
                  >
                    <LoaderSpinner loading={isLoading}>
                      Reset Password
                    </LoaderSpinner>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default ConfirmPasswordChange;
