import Badge from "components/Badge";
import Icon from "components/Icon";
import Table from "components/Table";
import TableHeader from "components/TableHeader/TableHeader";
import TooltipV2 from "components/TooltipV2/TooltipV2";
import DeleteCompanies from "./DeleteCompanies";
import WhitelistForm from "./WhitelistForm";

const Whitelist = () => {
  const data = [
    {
      id: 1,
      type: "Domain",
      name: "https://google.com",
    },
    {
      id: 2,
      type: "Team",
      name: "Facebook",
    },
    {
      id: 3,
      type: "Domain",
      name: "https://facebook.com",
    },
    {
      id: 4,
      type: "Domain",
      name: "https://ino-workplace.com",
    },
    {
      id: 5,
      type: "Team",
      name: "Amazon",
    },
  ];

  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0 flex items-center justify-between w-full">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
              Whitelist
            </h1>
            <WhitelistForm>
              {(setIsOpen) => (
                <button
                  className="btn flex items-center gap-x-1 bg-indigo-500 hover:bg-indigo-600 text-white text-sm rounded-md"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <Icon name="plus" />
                  Add whitelist data
                </button>
              )}
            </WhitelistForm>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg relative">
          <TableHeader withSearch>
            <h2 className="font-semibold text-slate-800 flex items-center gap-x-3">
              All Customers/Domains <Badge>{data.length}</Badge>
            </h2>
          </TableHeader>
          <Table
            headers={["Type", "Name", ""]}
            data={data}
            renderItem={(item) => {
              const isTeam = item.type.toLowerCase() === "team";

              return (
                <tr key={item.id}>
                  <td className="px-4 py-3 whitespace-nowrap w-px">
                    <Badge type={isTeam ? "success" : "warning"}>
                      {item.type}
                    </Badge>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap">{item.name}</td>
                  <td className="px-4 py-3 whitespace-nowrap">
                    <div className="flex items-center justify-end gap-x-3">
                      <TooltipV2 content="Edit">
                        <WhitelistForm
                          id={item.id}
                          name={item.name}
                          type={item.type.toLowerCase()}
                        >
                          {(setIsOpen) => (
                            <button
                              onClick={() => {
                                setIsOpen(true);
                              }}
                            >
                              <Icon name="edit-3" />
                            </button>
                          )}
                        </WhitelistForm>
                      </TooltipV2>
                      <TooltipV2 content="Delete">
                        <DeleteCompanies name={item.name} />
                      </TooltipV2>
                    </div>
                  </td>
                </tr>
              );
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default Whitelist;
