import Badge from "components/Badge";
import Icon from "components/Icon";
import Table from "components/Table";
import TableHeader from "components/TableHeader/TableHeader";
import TooltipV2 from "components/TooltipV2/TooltipV2";
import DeleteTeamModal from "./DeleteTeamsModal";
import TeamForm from "./TeamForm";

const TeamsTable = () => {
  const data = [
    {
      id: 1,
      name: "Google",
    },
    {
      id: 5,
      name: "Amazon",
    },
  ];

  return (
    <div className="bg-white shadow-lg rounded-lg relative">
      <TableHeader withSearch>
        <h2 className="font-semibold text-slate-800 flex items-center gap-x-3">
          All Customer Companies <Badge>{data.length}</Badge>
        </h2>
      </TableHeader>
      <Table
        headers={["Name", ""]}
        data={data}
        renderItem={(item) => {
          return (
            <tr key={item.id}>
              <td className="px-4 py-3 whitespace-nowrap font-bold">
                {item.name}
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <div className="flex items-center justify-end gap-x-3">
                  <TooltipV2 content="Edit">
                    <TeamForm id={item.id} name={item.name}>
                      {(setIsOpen) => (
                        <button
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          <Icon name="edit-3" />
                        </button>
                      )}
                    </TeamForm>
                  </TooltipV2>
                  <TooltipV2 content="Delete">
                    <DeleteTeamModal name={item.name} />
                  </TooltipV2>
                </div>
              </td>
            </tr>
          );
        }}
      />
    </div>
  );
};

export default TeamsTable;
