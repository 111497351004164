import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderSpinner from "components/LoaderSpinner";
import AuthImage from "assets/images/auth-image.jpg";
import AuthDecoration from "assets/images/auth-decoration.png";

import { useUserContext } from "contexts/UserContext";
import { useSignIn } from "hooks/useServerQueries";
import { PageURL } from "type-defs/Global";
import { LoginInputs } from "type-defs/Inputs";
import BeforeLogin from "components/SVGR/BeforeLogin";

function Signin() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>();
  const { setUser } = useUserContext();
  const { mutateAsync, isLoading } = useSignIn();
  const onSubmit: SubmitHandler<LoginInputs> = async (data: LoginInputs) => {
    if (data.email && data.password) {
      const res = await mutateAsync(data);
      if (res?.email) {
        localStorage.setItem("access", "loggedIn");
        localStorage.setItem("user", JSON.stringify(res));
        setUser(res);
        navigate(PageURL.Dashboard);
      } else {
        toast("An error occoured. Please try again", {
          type: "error",
          autoClose: 5000,
        });
      }
    }
  };
  return (
    <main className="bg-white">
      <div className="relative md:flex">
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                <Link className="block" to={PageURL.Home}>
                  <BeforeLogin />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto px-4 py-8">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">
                Welcome back! ✨
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      className="form-input w-full"
                      {...register("email", {
                        required: {
                          message: "Email is required",
                          value: true,
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                      })}
                    />
                    {errors?.email?.message && (
                      <p className="mt-1 text-red-500">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className="form-input w-full"
                      type="password"
                      {...register("password", {
                        required: {
                          message: "Password is required",
                          value: true,
                        },
                      })}
                    />
                    {errors?.password?.message && (
                      <p className="mt-1 text-red-500">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link
                      className="text-sm underline hover:no-underline"
                      to={PageURL.ResetPassword}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    className="btn w-20 h-9 bg-indigo-500 hover:bg-indigo-600 text-white flex justify-center items-center"
                    type="submit"
                    disabled={isLoading}
                  >
                    <LoaderSpinner loading={isLoading}>Sign On</LoaderSpinner>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default Signin;
