import React, { JSXElementConstructor, ReactElement, ReactNode } from "react";
import RCDropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { uniqueId } from "lodash";

interface MenuOption {
  label?: ReactNode;
  custom?: ReactNode;
  key?: string;
  disabled?: boolean;
  divider?: boolean;
}

type Props = {
  options: MenuOption[];
  onChange(key: string | number): any;
  children: ReactElement<
    HTMLButtonElement,
    string | JSXElementConstructor<any>
  >;
  animation?: string;
  placement?:
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight";
  [rest: string]: any;
};

const Dropdown = ({
  options,
  children,
  onChange,
  animation = "slide-up",
  placement = "bottomLeft",
  ...rest
}: Props) => {
  const onSelect = ({ key }: MenuOption) => {
    onChange(key as string);
  };

  const menu = (
    <Menu
      onSelect={onSelect}
      className="!my-0 !py-2 !shadow-none !rounded-md !overflow-hidden"
    >
      {options?.map((option: MenuOption) => {
        if (option?.disabled) {
          return (
            <MenuItem className="!px-3 !py-1" disabled key={uniqueId()}>
              {option.label}
            </MenuItem>
          );
        }

        if (option?.divider) {
          return (
            <li key={uniqueId()} className="!py-1.5">
              <div className="h-[1px] bg-gray-200" />
            </li>
          );
        }

        if (option?.custom) {
          return (
            <MenuItem key={option?.key} className="cursor-pointer !px-3 !py-1">
              {option.label}
            </MenuItem>
          );
        }

        return (
          <MenuItem
            key={option?.key}
            className="cursor-pointer !px-3 !py-1 hover:bg-gray-100"
          >
            {option.label}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <RCDropdown
      placement={placement}
      {...rest}
      trigger={["click"]}
      overlay={menu}
      animation={animation}
      overlayClassName="!rounded-md overflow-hidden !shadow-xl !text-sm"
    >
      {children}
    </RCDropdown>
  );
};

export default Dropdown;
