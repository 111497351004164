import Input from "components/Input";
import Modal from "components/Modal";
import Select from "components/Select";
import { ApprovalStatus } from "constants/ApprovalStatus";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import React, { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

type Props = {
  id?: number | null;
  name?: string;
  date?: string;
  status?: string;
  department?: string;
  children(setIsOpen: any): ReactNode;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  date: Yup.string().required("Date is required"),
  department: Yup.string().required("Department is required"),
});

const TestApprovalForm = ({
  id,
  name,
  date,
  status,
  department,
  children,
}: Props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: {
      id,
      name,
      date,
      status,
      department,
    },
  });
  const isEditing = !!id;
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = async (data: any) => {
    console.log(data);
    reset();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  return (
    <>
      {children(setIsOpen)}
      <Modal modalOpen={isOpen} setModalOpen={setIsOpen}>
        <div className="w-[30rem] p-8">
          <p className="whitespace-normal font-medium text-lg mb-4">
            {isEditing ? (
              <>
                Edit <span className="font-bold">{name}</span>
              </>
            ) : (
              "Create Test"
            )}
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-y-4"
          >
            <Input
              inputProps={register("name")}
              label="Name"
              error={errors.name}
            />
            <Input
              inputProps={{
                ...register("date"),
                type: "date",
              }}
              label="Date"
              error={errors.date}
            />
            <Input
              inputProps={register("department")}
              label="Department"
              error={errors.department}
            />
            <Select
              inputProps={register("status")}
              label="Status"
              error={errors.status}
              options={ApprovalStatus.map((option) => ({
                label: option,
                value: option,
              }))}
            />
            <div className="flex items-center justify-center gap-x-3">
              <button
                type="button"
                className="px-4 bg-gray-200 h-10 hover:bg-gray-300 rounded-md"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 bg-indigo-500 h-10 hover:bg-indigo-600 text-white rounded-md"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default TestApprovalForm;
