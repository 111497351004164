import { UserRole } from "type-defs/Global";

export const getRoleDisplayName = (role: UserRole) => {
  switch (role) {
    case UserRole.AITAdmin: {
      return "AIT Admin";
    }
    case UserRole.CustomerAdminUser: {
      return "Customer Admin User";
    }
    case UserRole.CustomerUser: {
      return "Customer User";
    }
    case UserRole.Approver: {
      return "Approver";
    }
    case UserRole.DeptManager: {
      return "DeptManager";
    }
    case UserRole.ExternalAuditor: {
      return "ExternalAuditor";
    }
    case UserRole.TestAuthor: {
      return "TestAuthor";
    }
    case UserRole.TestOwner: {
      return "TestOwner";
    }
    default: {
      return "Guest";
    }
  }
};
