import React, { useRef, SetStateAction, Dispatch, ReactNode } from "react";
import { createPortal } from "react-dom";
import Transition from "utils/Transition";

export interface Props {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

function Modal({ modalOpen, setModalOpen, children }: Props) {
  const modalContent = useRef<HTMLDivElement>(null);

  return createPortal(
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
        onClick={() => setModalOpen(false)}
        appear
      />
      {/* Modal dialog */}
      <Transition
        className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2  z-50 overflow-hidden flex items-start mb-4 justify-center px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
        appear
      >
        <div
          ref={modalContent}
          className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded-md shadow-lg"
        >
          {children}
        </div>
      </Transition>
    </>,
    document.body
  );
}

export default Modal;
