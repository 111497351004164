import React, { useState, useEffect, useMemo } from "react";
import Table from "components/Table/Table";
import Avatar from "components/Avatar/Avatar";
import Icon from "components/Icon";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import Dropdown from "components/Dropdown/Dropdown";
import TooltipV2 from "components/TooltipV2/TooltipV2";
import TableHeader from "components/TableHeader/TableHeader";

export interface Props {
  items: any[];
  selectedItems: (_selectedItems: number[]) => void;
  perPage: number;
  currentPage: number;
}

function AdminUsersTable({
  selectedItems,
  items,
  perPage,
  currentPage,
}: Props) {
  const paginationItems = useMemo(() => {
    const obegone = items.slice(
      perPage * (currentPage - 1),
      perPage * currentPage
    );
    return obegone;
  }, [items, perPage, currentPage]);

  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(paginationItems.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id: number, checked: boolean) => {
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  const handleOnSelect = (value: string) => {
    console.log(`api call for ${value} function`);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg relative">
      <TableHeader withSearch>
        <h2 className="font-semibold text-slate-800">
          All Users{" "}
          <span className="text-slate-400 font-medium">{items.length}</span>
        </h2>
      </TableHeader>
      <Table
        selectAllHeader={
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select all</span>
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
            </label>
          </div>
        }
        headers={["Order", "Email", "User Type", "Test run", "Last Test", ""]}
        data={paginationItems}
        renderItem={(item) => (
          <tr key={item.id} className="text-slate-600">
            <td className="px-4 py-3 whitespace-nowrap w-px">
              <div className="flex items-center gap-x-3">
                <label className="inline-flex">
                  <span className="sr-only">Select</span>
                  <Checkbox
                    checked={isCheck.includes(item.id) || selectAll}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleClick(item.id, e.target.checked);
                    }}
                  />
                </label>
                <Icon
                  name="star"
                  className={classNames(
                    "fill-current",
                    item.favorite ? "text-amber-500" : "text-slate-300"
                  )}
                />
              </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap font-medium text-slate-800">
              <div className="flex items-center gap-x-3">
                <Avatar src={item.avatar} className="h-10 w-10" />
                <p>{item.name}</p>
              </div>
            </td>
            <td className="px-4 py-3 whitespace-nowrap">{item.email}</td>
            <td className="px-4 py-3 whitespace-nowrap">{item.userType}</td>
            <td className="px-4 py-3 whitespace-nowrap">{item.testRun}</td>
            <td className="px-4 py-3 whitespace-nowrap font-medium text-sky-500">
              {item.lastTest}
            </td>
            <td className="px-4 py-3 whitespace-nowrap">
              <TooltipV2 content="More options">
                <Dropdown
                  options={[
                    {
                      label: "Maintenance",
                      key: "maintenance",
                    },
                    {
                      label: "Disable",
                      key: "disable",
                    },
                  ]}
                  onChange={handleOnSelect}
                  placement="bottomRight"
                >
                  <button className="text-slate-400 hover:text-slate-500">
                    <Icon name="more-horizontal" size={20} />
                  </button>
                </Dropdown>
              </TooltipV2>
            </td>
          </tr>
        )}
      />
    </div>
  );
}

export default AdminUsersTable;
