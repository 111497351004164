import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface Option {
  label: string;
  value: string | number;
}

type Props = {
  inputProps: any;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  label?: string;
  options?: Option[];
  defaultOption?: string;
};

const Select = ({
  label,
  inputProps,
  error,
  options,
  defaultOption,
}: Props) => {
  return (
    <div>
      {label && (
        <label htmlFor={inputProps?.name} className="block mb-1">
          {label}
        </label>
      )}

      <select
        className="border border-gray-200 rounded-md h-10 w-full focus:border-gray-400 outline-none px-2 text-sm"
        {...inputProps}
      >
        {defaultOption && <option value="">{defaultOption}</option>}
        {options?.map((item) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {error?.message && (
        // @ts-ignore
        <p className="text-xs text-red-500 mt-2">{error?.message}</p>
      )}
    </div>
  );
};

export default Select;
