import { useEffect, useState } from "react";
import { UserRole } from "type-defs/Global";

export interface Session {
  id: string | null;
  role: UserRole | null;
}

const useSession = () => {
  const [session, setSession] = useState<Session>({
    id: "loading",
    role: null,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("access");
    if (accessToken?.length) {
      setSession({ id: accessToken, role: UserRole.AITAdmin });
    } else {
      setSession({ id: null, role: null });
    }
  }, []);

  return session;
};

export default useSession;
