import classNames from "classnames";
import React, { ReactNode } from "react";

type Type = "success" | "danger" | "warning" | "default";

type Props = {
  type?: Type;
  className?: string;
  children: ReactNode;
};

const Badge = ({ type = "default", className, children }: Props) => {
  const getTypeClass = () => {
    if (type === "success") {
      return "bg-emerald-100 text-emerald-600";
    }

    if (type === "danger") {
      return "bg-red-100 text-red-600";
    }

    if (type === "warning") {
      return "bg-amber-100 text-amber-600";
    }

    return "bg-slate-100 text-slate-500";
  };

  return (
    <div
      className={classNames(
        "text-xs px-2 py-0.5 rounded-md inline-grid place-items-center",
        className,
        getTypeClass()
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
