import { UserProfile } from "interfaces/Global";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

export interface IUserContext {
  setUser: Dispatch<SetStateAction<UserProfile | null>>;
  user: UserProfile | null;
}

const userContextDefaults: IUserContext = {
  setUser: () => null,
  user: null,
};

export const UserContext = createContext<IUserContext>(userContextDefaults);

export const useUserContext = () => useContext<IUserContext>(UserContext);
