import Input from "components/Input";
import Modal from "components/Modal";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import React, { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

type Props = {
  id?: number | null;
  name?: string;
  children(setIsOpen: any): ReactNode;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

const TeamForm = ({ id = null, name = "", children }: Props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: {
      id,
      name,
    },
  });
  const isEditing = !!(id && name);
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (data: any) => {
    console.log(data);
    reset();
    setIsOpen(false);
  };

  return (
    <>
      {children(setIsOpen)}
      <Modal modalOpen={isOpen} setModalOpen={setIsOpen}>
        <div className="w-[30rem] p-8">
          <p className="whitespace-normal font-medium text-lg mb-4">
            {isEditing ? "Edit" : "Create"} Team
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-y-4"
          >
            <Input
              inputProps={register("name")}
              label="Name"
              error={errors.name}
            />
            <button
              type="submit"
              className="bg-indigo-500 h-10 hover:bg-indigo-600 text-white rounded-md"
            >
              {isEditing ? "Save changes" : "Create"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default TeamForm;
