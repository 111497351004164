/* istanbul ignore file */
/* eslint-disable no-nested-ternary */

import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useSession from "hooks/useSession";
import { PageURL, UserRole } from "type-defs/Global";

const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const { id, role } = useSession();
  const location = useLocation();
  const shouldRedirect =
    !id && role !== UserRole.AITAdmin && location.pathname !== PageURL.Home;
  return id !== "loading" ? (
    shouldRedirect ? (
      <Navigate to={PageURL.Home} />
    ) : (
      children
    )
  ) : (
    <div />
  );
};

export default PrivateRoute;
