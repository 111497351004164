const allUsers = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@gmail.com",
    userType: "Super Admin",
    testRun: 24,
    lastTest: "#123123",
    avatar: "https://i.pravatar.cc/150?img=1",
    favorite: true,
  },
  {
    id: 2,
    name: "Denzel Doe",
    email: "denzeldoe@gmail.com",
    userType: "Admin",
    testRun: 77,
    lastTest: "#123123",
    avatar: "https://i.pravatar.cc/150?img=2",
    favorite: false,
  },
  {
    id: 3,
    name: "Jackson Doe",
    email: "jacksondoe@gmail.com",
    userType: "Admin",
    testRun: 44,
    lastTest: "#123123",
    avatar: "https://i.pravatar.cc/150?img=3",
    favorite: false,
  },
  {
    id: 4,
    name: "Sean Doe",
    email: "seandoe@gmail.com",
    userType: "Admin",
    testRun: 29,
    lastTest: "#123123",
    avatar: "https://i.pravatar.cc/150?img=4",
    favorite: false,
  },
  {
    id: 5,
    name: "Gerald Doe",
    email: "geralddoe@gmail.com",
    userType: "Admin",
    testRun: 22,
    lastTest: "#123123",
    avatar: "https://i.pravatar.cc/150?img=5",
    favorite: true,
  },
  {
    id: 6,
    name: "Jerico Doe",
    email: "jericodoe@gmail.com",
    userType: "Admin",
    testRun: 18,
    lastTest: "#123123",
    avatar: "https://i.pravatar.cc/150?img=6",
    favorite: false,
  },
];

export default allUsers;
