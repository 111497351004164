import React from "react";

const ErrorComponent = () => {
  return (
    <div>
      <p className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-lg text-red-600">
        An error occoured. Try refreshing this page
      </p>
    </div>
  );
};

export default ErrorComponent;
