/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
// @ts-nocheck

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AITLogo from "assets/images/logos/ait-small.png";
import { PageURL } from "type-defs/Global";
import SidebarLinkGroup from "components/SidebarLinkGroup";
import Icon from "components/Icon";
import classNames from "classnames";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      />

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to={PageURL.Dashboard} className="block bg-slate-200">
            <img src={AITLogo} />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul className="mt-3 flex flex-col gap-y-2">
              {/* Super admin */}
              <SidebarLinkGroup
                active={pathname === "/" || pathname.includes("superadmin")}
              >
                {(handleClick, open) => {
                  return (
                    <>
                      <a
                        href="#0"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("superadmin") &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <Icon
                              name="alert-triangle"
                              size={20}
                              className={classNames(
                                pathname.includes("superadmin") &&
                                  "!text-indigo-500"
                              )}
                            />
                            {sidebarExpanded && (
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Super Admin
                              </span>
                            )}
                          </div>
                          {/* Icon */}
                          <Icon name={open ? "chevron-up" : "chevron-down"} />
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-8 mt-1 ${!open && "hidden"}`}>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/superadmin"
                              className={({ isActive }) =>
                                `block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                  isActive ? "!text-indigo-500" : ""
                                }`
                              }
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Manage
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/superadmin/whitelist"
                              className={({ isActive }) =>
                                `block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                  isActive ? "!text-indigo-500" : ""
                                }`
                              }
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Whitelist Customer
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </>
                  );
                }}
              </SidebarLinkGroup>

              {/* Dashboard */}
              <SidebarLinkGroup
                active={pathname === "/" || pathname.includes("dashboard")}
              >
                {(handleClick, open) => {
                  return (
                    <>
                      <a
                        href="#0"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          (pathname === "/" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <Icon
                              name="bar-chart-2"
                              size={20}
                              className={classNames(
                                pathname.includes("dashboard") &&
                                  "!text-indigo-500"
                              )}
                            />
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Dashboard
                            </span>
                          </div>
                          {/* Icon */}
                          <Icon name={open ? "chevron-up" : "chevron-down"} />
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-8 mt-1 ${!open && "hidden"}`}>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/dashboard"
                              className={({ isActive }) =>
                                `block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                  isActive ? "!text-indigo-500" : ""
                                }`
                              }
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Main
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/dashboard/testrunner"
                              className={({ isActive }) =>
                                `block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                  isActive ? "!text-indigo-500" : ""
                                }`
                              }
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Run Tests
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/dashboard/adminusers"
                              className={({ isActive }) =>
                                `block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                  isActive ? "!text-indigo-500" : ""
                                }`
                              }
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Admin Users
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </>
                  );
                }}
              </SidebarLinkGroup>
              {/* Account Settings */}
              <li
                className={classNames(
                  "px-3 py-2 rounded-sm",
                  pathname.includes(PageURL.AccountSettings) && "bg-slate-900"
                )}
              >
                <NavLink
                  end
                  to={PageURL.AccountSettings}
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes(PageURL.AccountSettings) &&
                    "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <Icon
                      name="user"
                      size={20}
                      className={classNames(
                        pathname.includes(PageURL.AccountSettings) &&
                          "!text-indigo-500"
                      )}
                    />
                    {sidebarExpanded && (
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Account Settings
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>
              {/* Test Library */}
              <li
                className={classNames(
                  "px-3 py-2 rounded-sm",
                  pathname.includes(PageURL.TestLibrary) && "bg-slate-900"
                )}
              >
                <NavLink
                  end
                  to={PageURL.TestLibrary}
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes(PageURL.TestLibrary) &&
                    "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">
                      <Icon
                        name="folder"
                        size={20}
                        className={classNames(
                          pathname.includes(PageURL.TestLibrary) &&
                            "!text-indigo-500"
                        )}
                      />
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Test Library
                      </span>
                    </div>
                    {/* Badge */}
                    <div className="flex flex-shrink-0 gap-x-2 ml-2">
                      <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-green-500 px-2 rounded">
                        5
                      </span>
                      <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-red-500 px-2 rounded">
                        1
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
              {/* Test Schedule */}
              <li
                className={classNames(
                  "px-3 py-2 rounded-sm",
                  pathname.includes(PageURL.TestSchedule) && "bg-slate-900"
                )}
              >
                <NavLink
                  end
                  to={PageURL.TestSchedule}
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes(PageURL.TestSchedule) &&
                    "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <Icon
                      name="calendar"
                      size={20}
                      className={classNames(
                        pathname.includes(PageURL.TestSchedule) &&
                          "!text-indigo-500"
                      )}
                    />
                    {sidebarExpanded && (
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Test Schedule
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>
              {/* Test Log */}
              <li
                className={classNames(
                  "px-3 py-2 rounded-sm",
                  pathname.includes(PageURL.TestingLog) && "bg-slate-900"
                )}
              >
                <NavLink
                  end
                  to={PageURL.TestingLog}
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname.includes(PageURL.TestingLog) &&
                    "hover:text-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <Icon
                      name="file-text"
                      size={20}
                      className={classNames(
                        pathname.includes(PageURL.TestingLog) &&
                          "!text-indigo-500"
                      )}
                    />
                    {sidebarExpanded && (
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Testing Log
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>
              {/* Test Approvals */}
              <li
                className={classNames(
                  "px-3 py-2 rounded-sm",
                  pathname.includes(PageURL.Approvals) && "bg-slate-900"
                )}
              >
                <NavLink
                  end
                  to={PageURL.Approvals}
                  className="block text-slate-200 hover:text-white truncate transition duration-150"
                >
                  <div className="flex items-center">
                    <Icon
                      name="git-merge"
                      size={20}
                      className={classNames(
                        pathname.includes(PageURL.Approvals) &&
                          "!text-indigo-500"
                      )}
                    />
                    {sidebarExpanded && (
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Testing Approvals
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <Icon
                name={
                  sidebarExpanded ? "arrow-left-circle" : "arrow-right-circle"
                }
                size={24}
                className="text-white"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
