import React from "react";
import { useParams } from "react-router-dom";
import AddTestPanel from "components/AddTestPanel";
import ErrorComponent from "components/Error";
import Loading from "components/Loading";
import { useGetSingleTest } from "hooks/useServerQueries";

const UpdateTest = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetSingleTest(
    id ? parseInt(id, 10) : 0
  );

  if (isLoading) return <Loading />;
  if (isError) return <ErrorComponent />;
  if (!id || data === null) {
    return (
      <p className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        Test Not Found
      </p>
    );
  }

  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="mb-8">
          {/* Title */}
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
            Update Test &quot;{data?.name || ""}&quot;
          </h1>
        </div>

        {/* Content */}
        <div className="bg-white shadow-lg rounded-lg mb-8">
          <div className="flex flex-col md:flex-row md:-mr-px">
            <AddTestPanel currentTest={data} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default UpdateTest;
