import Icon from "components/Icon";
import React from "react";
import TeamForm from "./TeamForm";
import TeamsTable from "./TeamsTable";
import UserForm from "./UserForm";
import UsersTable from "./UsersTable";

const Manage = () => {
  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0 flex items-center justify-between w-full">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
              Manage
            </h1>
            <div className="flex items-center gap-x-3">
              <TeamForm>
                {(setIsOpen) => (
                  <button
                    className="btn flex items-center gap-x-1 bg-gray-200 hover:bg-gray-300 text-black text-sm rounded-md"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <Icon name="plus" />
                    Add Team
                  </button>
                )}
              </TeamForm>
              <UserForm>
                {(setIsOpen) => (
                  <button
                    className="btn flex items-center gap-x-1 bg-indigo-500 hover:bg-indigo-600 text-white text-sm rounded-md"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <Icon name="plus" />
                    Add User
                  </button>
                )}
              </UserForm>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-10">
          <TeamsTable />
          <UsersTable />
        </div>
      </div>
    </main>
  );
};

export default Manage;
