import { UserProfile } from "interfaces/Global";
import { useQuery, UseQueryResult } from "react-query";
import { getProfile } from "services/user-profile.services";
import { QUERY_KEYS } from "hooks/useServerQueries";

export const useUserProfile = (): UseQueryResult<UserProfile | null> => {
  return useQuery([QUERY_KEYS.GET_ME], async () => {
    const data = await getProfile();
    return data;
  });
};
