import Input from "components/Input";
import Modal from "components/Modal";
import { useRequestPasswordChange } from "hooks/useServerQueries";
import useYupValidationResolver from "hooks/useYupValidationResolver";
// import { useRequestPasswordChange } from "hooks/useServerQueries";
import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const ChangePasswordModal = ({ isOpen, setIsOpen }: Props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver });

  const { mutateAsync, isLoading } = useRequestPasswordChange();

  const onSubmit = async (data: any) => {
    mutateAsync(data);
    toast("Password has been updated", {
      type: "success",
      autoClose: 3000,
    });

    setIsOpen(false);
    reset();
  };

  return (
    <Modal modalOpen={isOpen} setModalOpen={setIsOpen}>
      <div className="w-[30rem] p-8">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-y-4"
        >
          <Input
            inputProps={{
              ...register("password"),
              type: "password",
            }}
            label="Password"
            error={errors?.password}
          />
          <Input
            inputProps={{
              ...register("confirmPassword"),
              type: "password",
            }}
            label="Confirm Password"
            error={errors?.confirmPassword}
          />
          <button
            type="submit"
            className="bg-indigo-500 h-10 hover:bg-indigo-600 text-white rounded-md"
            disabled={isLoading}
          >
            Change Password
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
