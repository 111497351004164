import classNames from "classnames";

type Props = {
  src: string;
  className?: string;
};

const Avatar = ({ src, className }: Props) => {
  return (
    <div className={classNames("rounded-full overflow-hidden", className)}>
      <img
        src={src}
        className="h-full object-cover object-center"
        alt="avatar"
      />
    </div>
  );
};

export default Avatar;
