import _ from "lodash";
import Image01 from "assets/svgs/icon-01.svg";
import Image02 from "assets/svgs/icon-02.svg";
import Image03 from "assets/svgs/icon-03.svg";
import {
  BatchType,
  TestStatus,
  TestType,
  ApprovalTestStatus,
} from "type-defs/Global";
import { TestRowType } from "interfaces/Global";

const images = [Image01, Image02, Image03];
const names = ["Item Add", "Customer Add", "Vendor Add", "Misc Issue Pass"];
const departments = [
  "Engineering",
  "Customer Service",
  "Procurement",
  "Operations",
];
const batchtypes: BatchType[] = ["All", "Single"];
const statuses: TestStatus[] = ["Completed", "Error", "Pending", "Warning"];
const sequence = ["0", "1", "2"];
const testTypes: TestType[] = ["One-time", "Recurring"];
const formTypes = ["Items", "Customer", "Vendor"];
const approvalTestStatuses: ApprovalTestStatus[] = [
  "Error",
  "In Review",
  "Reported",
];
const codes = ["UI Error", "Transaction", "Unknown"];
const booleans = [0, 1];
const times = ["15:01", "13:01", "07:01", "03:10"];

export const allTests: TestRowType[] = [
  ...new Array(Math.floor(Math.random() * 40)).fill(null).map((_obj, idx) => ({
    id: idx + 1,
    image: _.sample(images) || "",
    name: _.sample(names) || "",
    date: "2022-11-04",
    department: _.sample(departments) || "",
    batchtype: _.sample(batchtypes) || "Single",
    status: _.sample(statuses) || "Pending",
    sequence: _.sample(sequence) || "1",
    location: `https://test-server/AITBSLLC_AX${idx + 1}`,
    type: _.sample(testTypes) || "One-time",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    form: _.sample(formTypes),
    approvalStatus: _.sample(approvalTestStatuses) || "In Review",
    code: _.sample(codes) || "",
    detail:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    pass: _.sample(booleans) || 0,
    isApproved: _.sample(booleans) || 0,
    starttime: _.sample(times) || "07:01",
    endtime: _.sample(times) || "07:01",
  })),
];
