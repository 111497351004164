import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import UserAvatar from "assets/images/user-avatar-32.png";
import { useUserContext } from "contexts/UserContext";
// import { getRoleDisplayName } from "utils";
import { PageURL } from "type-defs/Global";
import Icon from "components/Icon";
import Dropdown from "components/Dropdown/Dropdown";
import { getRoleDisplayName } from "utils";

function DropdownProfile() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();

  const onSelect = (value: string) => {
    setDropdownOpen(false);

    switch (value) {
      case "sign-out": {
        localStorage.removeItem("access");
        localStorage.removeItem("user");
        setUser(null);
        navigate("/");
        return;
      }

      case "settings": {
        navigate(PageURL.AccountSettings);
        return;
      }

      default: {
        console.log(value);
      }
    }
  };

  return (
    <Dropdown
      options={[
        {
          custom: true,
          label: (
            <div className="flex flex-col hover:bg-none">
              <span>{user?.fullName}</span>
              {user?.role && (
                <span className="text-gray-400">
                  {getRoleDisplayName(user?.role)}
                </span>
              )}
            </div>
          ),
        },
        {
          divider: true,
        },
        {
          label: "Settings",
          key: "settings",
        },
        {
          label: "Sign Out",
          key: "sign-out",
        },
      ]}
      placement="bottomRight"
      onChange={onSelect}
      onVisibleChange={(state: boolean) => {
        setDropdownOpen(state);
      }}
    >
      <button className="flex items-center gap-x-2">
        <img
          className="w-8 h-8 rounded-full"
          src={user?.profile || UserAvatar}
          width="32"
          height="32"
          alt="User"
        />
        {user?.fullName}
        <Icon name={dropdownOpen ? "chevron-up" : "chevron-down"} />
      </button>
    </Dropdown>
  );
}

export default DropdownProfile;
