import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "components/ErrorBoundary";
import { UserProfile } from "interfaces/Global";
import { UserContext } from "contexts/UserContext";
import Router from "./Router";
import "react-toastify/dist/ReactToastify.css";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 30 * 60 * 1000, // 30min
    },
  },
});

function App() {
  const [user, setUser] = useState<UserProfile | null>(null);
  return (
    <ErrorBoundary>
      <UserContext.Provider value={{ setUser, user }}>
        <QueryClientProvider client={queryClient}>
          <Router />
          <ToastContainer />
        </QueryClientProvider>
      </UserContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
