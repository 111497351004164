import React, { useState, useEffect, useMemo } from "react";
import { TestRowType } from "interfaces/Global";
import Table from "components/Table/Table";
import Checkbox from "components/Checkbox";
import Avatar from "components/Avatar";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Icon from "components/Icon";
import TableHeader from "components/TableHeader/TableHeader";

export interface Props {
  items: TestRowType[];
  selectedItems: (_selectedItems: number[]) => void;
  perPage: number;
  currentPage: number;
}

function TestScheduleTable({
  selectedItems,
  items,
  perPage,
  currentPage,
}: Props) {
  const paginationItems = useMemo(() => {
    const obegone = items.slice(
      perPage * (currentPage - 1),
      perPage * currentPage
    );
    return obegone;
  }, [items, perPage, currentPage]);
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any[]>([]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(paginationItems.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id: number, checked: boolean) => {
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-lg relative">
      <TableHeader withSearch>
        <h2 className="font-semibold text-slate-800">
          All Tests{" "}
          <span className="text-slate-400 font-medium">{items.length}</span>
        </h2>
      </TableHeader>
      <div>
        <Table
          headers={[
            <div className="flex items-center">
              <label className="inline-flex">
                <span className="sr-only">Select all</span>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </label>
            </div>,
            "Name",
            "Scheduled",
            "Department",
            "Sequence",
            "Batch Type",
            "Recurring",
          ]}
          data={paginationItems}
          renderItem={(item) => {
            const menuLabel =
              item.type === "Recurring" ? "Recurring" : "One-time";
            const typeIcon = () => {
              switch (item.type) {
                case "Recurring":
                  return <Icon name="refresh-ccw" className="text-slate-400" />;
                default:
                  return (
                    <Icon name="git-pull-request" className="text-slate-400" />
                  );
              }
            };

            return (
              <tr key={item.id} className="text-slate-600">
                <td className="px-4 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select</span>
                      <Checkbox
                        checked={isCheck.includes(item.id) || selectAll}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleClick(item.id, e.target.checked);
                        }}
                      />
                    </label>
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <Link
                    to={`/testlibrary/details/${item.id}`}
                    className="font-medium text-sky-500"
                  >
                    <div className="flex items-center gap-x-2">
                      <Avatar src={item.image} className="h-6 w-6" />
                      {item.name}
                    </div>
                  </Link>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  {format(new Date(item.date), "yyyy-MM-dd")}
                </td>
                <td className="px-4 py-3 font-medium text-slate-800 whitespace-nowrap">
                  {item.department}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">{item.sequence}</td>
                <td className="px-4 py-3 whitespace-nowrap">
                  {item.batchtype}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="flex items-center gap-x-2">
                    {typeIcon()}
                    <div>{menuLabel}</div>
                  </div>
                </td>
              </tr>
            );
          }}
        />
      </div>
    </div>
  );
}

export default TestScheduleTable;
