import { ReactElement, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import PrivateRoute from "components/PrivateRoute";
import ConfirmPasswordChange from "pages/ConfirmPasswordChange";
import DashboardAccountSettings from "pages/AccountSettings";
import DashboardLanding from "pages/Dashboard/Landing";
import DashboardTestApprovals from "pages/TestApprovals";
import TestDetails from "pages/Dashboard/TestDetails";
import DashboardTestLibrary from "pages/TestLibrary";
import DashboardTestLog from "pages/TestLog";
import DashboardTestSchedule from "pages/TestSchedule";
import UpdateTest from "pages/Dashboard/UpdateTest";
import AdminUsers from "pages/Dashboard/Admin/Users";
import Login from "pages/Login";
import ResetPassword from "pages/ResetPassword";
import { PageURL } from "type-defs/Global";
import { useUserProfile } from "hooks/useUserProfile";
import { useUserContext } from "contexts/UserContext";
import DefaultLayout from "layouts/DefaultLayout";
import Manage from "pages/SuperAdmin/Manage/Manage";
import Whitelist from "pages/SuperAdmin/Whitelist/Whitelist";

function Router(): ReactElement {
  const { data, isLoading, isError } = useUserProfile();
  const { user, setUser } = useUserContext();

  useEffect(() => {
    if (data?.fullName && !user?.fullName) {
      setUser(data);
    }
  }, [data, user, setUser]);

  if (isLoading) {
    return <div />;
  }

  if (isError) {
    return <div>An error occoured</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={PageURL.Home}
          element={
            <ErrorBoundary>
              <Login />
            </ErrorBoundary>
          }
        />
        <Route
          path={PageURL.ResetPassword}
          element={
            <ErrorBoundary>
              <ResetPassword />
            </ErrorBoundary>
          }
        />
        <Route
          path={PageURL.ConfirmPasswordChange}
          element={
            <ErrorBoundary>
              <ConfirmPasswordChange />
            </ErrorBoundary>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <DefaultLayout />
              </ErrorBoundary>
            </PrivateRoute>
          }
        >
          <Route
            path={PageURL.AccountSettings}
            element={
              <ErrorBoundary>
                <DashboardAccountSettings />
              </ErrorBoundary>
            }
          />
          <Route
            path={PageURL.TestLibrary}
            element={
              <ErrorBoundary>
                <DashboardTestLibrary />
              </ErrorBoundary>
            }
          />
          <Route
            path={PageURL.TestSchedule}
            element={
              <ErrorBoundary>
                <DashboardTestSchedule />
              </ErrorBoundary>
            }
          />
          <Route
            path={PageURL.TestingLog}
            element={
              <ErrorBoundary>
                <DashboardTestLog />
              </ErrorBoundary>
            }
          />
          <Route
            path={PageURL.UpdateTest}
            element={
              <ErrorBoundary>
                <UpdateTest />
              </ErrorBoundary>
            }
          />
          <Route
            path={PageURL.TestDetails}
            element={
              <ErrorBoundary>
                <TestDetails />
              </ErrorBoundary>
            }
          />
          <Route
            path={PageURL.Approvals}
            element={
              <ErrorBoundary>
                <DashboardTestApprovals />
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <DefaultLayout />
                <DashboardLanding />
              </ErrorBoundary>
            </PrivateRoute>
          }
        />
        <Route
          path={PageURL.Dashboard}
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <DefaultLayout />
              </ErrorBoundary>
            </PrivateRoute>
          }
        >
          <Route index element={<DashboardLanding />} />
          <Route
            path={PageURL.DashboardAdmin}
            element={
              <ErrorBoundary>
                <AdminUsers />
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path={PageURL.SuperAdminManage}
          element={
            <PrivateRoute>
              <ErrorBoundary>
                <DefaultLayout />
              </ErrorBoundary>
            </PrivateRoute>
          }
        >
          <Route index element={<Manage />} />
          <Route
            path={PageURL.SuperAdminWhitelist}
            element={
              <ErrorBoundary>
                <Whitelist />
              </ErrorBoundary>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to={PageURL.Home} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
