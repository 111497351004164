import React from "react";

import { Link } from "react-router-dom";
import WelcomeBanner from "components/Dashboard/WelcomeBanner";
import RecentTestsActivity from "components/Dashboard/RecentTestsActivity";
import CardWithLineChart from "components/Dashboard/CardWithLineChart";
import CardWithRealTimeChart from "components/Dashboard/CardWithRealTimeChart";
import { PageURL } from "type-defs/Global";
import Icon from "components/Icon";

const DashboardLanding = () => {
  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <WelcomeBanner />
        <div className="sm:flex sm:justify-end sm:items-center mb-8">
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <Link
              to={PageURL.AddTest}
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <Icon name="plus" />
              <span className="hidden xs:block ml-2">Add a new test</span>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-6">
          <RecentTestsActivity />
        </div>
        <div className="grid grid-cols-10 gap-6 mt-5">
          <CardWithLineChart />
          {/* <CardWithDougnutChart /> */}
        </div>
        <div className="grid grid-cols-1 gap-6 mt-5">
          <CardWithRealTimeChart />
        </div>
      </div>
    </main>
  );
};

export default DashboardLanding;
