import React, { useState, useEffect, useMemo } from "react";
import { TestRowType } from "interfaces/Global";
import Table from "components/Table/Table";
import Checkbox from "components/Checkbox";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Badge from "components/Badge";
import TableHeader from "components/TableHeader/TableHeader";

export interface Props {
  items: TestRowType[];
  selectedItems: (_selectedItems: number[]) => void;
  perPage: number;
  currentPage: number;
}

function TestsLogTable({ selectedItems, items, perPage, currentPage }: Props) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);

  const paginationItems = useMemo(() => {
    const obegone = items.slice(
      perPage * (currentPage - 1),
      perPage * currentPage
    );
    return obegone;
  }, [items, perPage, currentPage]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(paginationItems.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (id: number, checked: boolean) => {
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-lg relative">
      <TableHeader withSearch>
        <h2 className="font-semibold text-slate-800">
          All Tests{" "}
          <span className="text-slate-400 font-medium">{items.length}</span>
        </h2>
      </TableHeader>
      <div>
        <Table
          selectAllHeader={
            <div className="flex items-center">
              <label className="inline-flex">
                <span className="sr-only">Select all</span>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </label>
            </div>
          }
          headers={["Name", "Date", "Start", "End", "Pass/Fail", "Detail"]}
          data={paginationItems}
          renderItem={(item) => {
            const statusType = () => {
              return item.pass ? "success" : "danger";
            };

            return (
              <tr key={item.id} className="text-slate-600">
                <td className="px-4 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select</span>
                      <Checkbox
                        checked={isCheck.includes(item.id) || selectAll}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleClick(item.id, e.target.checked);
                        }}
                      />
                    </label>
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <Link
                    to={`/testlibrary/details/${item.id}`}
                    className="font-medium text-sky-500"
                  >
                    {item.name}
                  </Link>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  {format(new Date(item.date), "yyyy-MM-dd")}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  {item.starttime}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">{item.endtime}</td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <Badge type={statusType()}>
                    {item.pass ? "Pass" : "Fail"}
                  </Badge>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <a href="#">{item.detail}</a>
                </td>
              </tr>
            );
          }}
        />
      </div>
    </div>
  );
}

export default TestsLogTable;
