import { ReactNode } from "react";

export enum PageURL {
  Home = "/",
  SignUp = "/signup",
  ResetPassword = "/reset-password",
  ConfirmPasswordChange = "/confirm-password-change",
  Dashboard = "/dashboard",
  DashboardAdmin = "/dashboard/adminusers",
  AddUser = "/dashboard/adminusers/add-user",
  AccountSettings = "/account-settings",
  TestLibrary = "/testlibrary",
  UpdateTest = "/testlibrary/:id",
  TestSchedule = "/testschedule",
  TestingLog = "/testinglog",
  Approvals = "/approvals",
  AddTest = "/add-test",
  TestDetails = "/testlibrary/details/:id",
  SuperAdminManage = "/superadmin",
  SuperAdminWhitelist = "/superadmin/whitelist",
}

export interface TextProps {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  span?: boolean;
  label?: boolean;
  error?: boolean;
  noStack?: boolean;
  margin?: boolean;
  marginTop?: string;
  align?: "left" | "right" | "center" | "justify";
  fontWeight?: "lighter" | "normal" | "bold" | "bolder";
  color?: string;
  ellipsis?: boolean;
  fontSize?: string;
  children: ReactNode;
}

export enum UserRole {
  "AITAdmin" = "AITAdmin",
  "Approver" = "Approver",
  "CustomerAdminUser" = "CustomerAdminUser",
  "CustomerUser" = "CustomerUser",
  "DeptManager" = "DeptManager",
  "ExternalAuditor" = "ExternalAuditor",
  "Guest" = "Guest",
  "TestAuthor" = "TestAuthor",
  "TestOwner" = "TestOwner",
}

export type BatchType = "All" | "Single";
export type TestType = "Recurring" | "One-time";
export type TestStatus = "Pending" | "Warning" | "Completed" | "Error";
export type ApprovalTestStatus = "Reported" | "In Review" | "Error";

export type DatePeriodType =
  | "Today"
  | "Last 7 Days"
  | "Last Month"
  | "Last 12 Months"
  | "All Time";

export interface DateFilterType {
  id: number;
  period: DatePeriodType;
}
