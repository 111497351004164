import React, { useState } from "react";
import FilterButton from "components/DropdownFilter";
import DeleteButton from "components/DeleteButton";
import DateSelect from "components/DateSelect";
import PaginationClassic from "components/PaginationClassic";
import {
  useAdminUsers,
  useDeleteTestsFromLibrary,
} from "hooks/useServerQueries";
import Loading from "components/Loading";
import ErrorComponent from "components/Error";
import { DatePeriodType } from "type-defs/Global";
import { TestSortType } from "interfaces/Global";
import AdminUsersTable from "components/AdminUsersTable";
import Icon from "components/Icon";
import AddUserModal from "pages/Dashboard/Admin/Users/AddUserModal";
import InviteUserModal from "pages/Dashboard/Admin/Users/InviteUserModal";

const DashboardAdminUsers = () => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdding, setIsAdding] = useState(false);
  const [isInviting, setIsInviting] = useState(false);

  const [selectedDuration, setSelectedDuration] =
    useState<DatePeriodType>("All Time");
  const [selectedSortOptions, setSelectedSortOptions] = useState<TestSortType>({
    batchType: false,
    date: false,
    name: false,
    recurring: false,
    result: false,
    status: false,
  });
  const { data, isLoading, isError, refetch } = useAdminUsers(
    currentPage,
    selectedDuration,
    selectedSortOptions
  );
  const { mutateAsync, isLoading: isDeleting } = useDeleteTestsFromLibrary();

  const handleSelectedItems = (_selectedItems: number[]) => {
    if (_selectedItems.length) {
      setSelectedItems([..._selectedItems]);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <>
      <main>
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          {/* Page header */}
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            {/* Left: Title */}
            <div className="mb-4 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                Users ✨
              </h1>
            </div>

            {/* Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              {/* Delete button */}
              <DeleteButton
                selectedItems={selectedItems}
                isDeleting={isDeleting}
                refetch={refetch}
                mutateAsync={mutateAsync}
                handleSelectedItems={handleSelectedItems}
              />
              {/* Dropdown */}
              <DateSelect
                onChange={(period: DatePeriodType) =>
                  setSelectedDuration(period)
                }
              />
              {/* Filter button */}
              <FilterButton
                align="right"
                onChange={(options) => setSelectedSortOptions(options)}
              />
              <button
                type="button"
                className="btn bg-gray-200 hover:bg-gray-300 text-black"
                onClick={() => {
                  setIsInviting(true);
                }}
              >
                <Icon name="send" />
                <span className="hidden xs:block ml-2">Invite User</span>
              </button>
              <button
                type="button"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => {
                  setIsAdding(true);
                }}
              >
                <Icon name="plus" />
                <span className="hidden xs:block ml-2">Add User</span>
              </button>
            </div>
          </div>
          {isLoading ? <Loading /> : null}
          {isError ? <ErrorComponent /> : null}
          {isLoading || isError || !data?.length ? null : (
            <>
              {/* Table */}
              <AdminUsersTable
                selectedItems={handleSelectedItems}
                items={data}
                currentPage={currentPage}
                perPage={10}
              />

              {/* Pagination */}
              <div className="mt-8">
                <PaginationClassic
                  currentPage={currentPage}
                  next={() => {
                    setCurrentPage((prev) => prev + 1);
                  }}
                  prev={() => {
                    setCurrentPage((prev) => prev - 1);
                  }}
                  perPage={10}
                  total={data.length}
                />
              </div>
            </>
          )}
        </div>
      </main>
      <AddUserModal isOpen={isAdding} setIsOpen={setIsAdding} />
      <InviteUserModal isOpen={isInviting} setIsOpen={setIsInviting} />
    </>
  );
};

export default DashboardAdminUsers;
