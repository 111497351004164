import Input from "components/Input";
import Modal from "components/Modal";
import Select from "components/Select";
import useYupValidationResolver from "hooks/useYupValidationResolver";
// import { useRequestPasswordChange } from "hooks/useServerQueries";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  userType: Yup.string().required("User type is required"),
});

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const InviteModal = ({ isOpen, setIsOpen }: Props) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver });
  const isLoading = false;

  // const { mutate } = useUpdateAdminUsers();

  const onSubmit = async () => {
    // mutate({
    //   data,
    //   id: uniqueId(),
    // });
    toast("User has been invited", {
      type: "success",
      autoClose: 3000,
    });

    setIsOpen(false);
    reset();
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal modalOpen={isOpen} setModalOpen={setIsOpen}>
      <div className="w-[30rem] p-8">
        <h1 className="text-2xl font-medium mb-2">Invite User</h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-y-4"
        >
          <Input
            inputProps={register("email")}
            label="Email Address"
            error={errors?.email}
          />
          <Select
            inputProps={register("userType")}
            label="User Type"
            error={errors?.userType}
            options={[
              {
                label: "Super Admin",
                value: "Super Admin",
              },
              {
                label: "Admin",
                value: "Admin",
              },
            ]}
            defaultOption="Select user type..."
          />
          <button
            type="submit"
            className="bg-indigo-500 h-10 hover:bg-indigo-600 text-white rounded-md mt-2"
            disabled={isLoading}
          >
            Invite User
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default InviteModal;
