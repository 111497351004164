import Dropdown from "components/Dropdown/Dropdown";
import Icon from "components/Icon";
import React, { useState } from "react";
import { DateFilterType, DatePeriodType } from "type-defs/Global";

export interface Props {
  onChange: (period: DatePeriodType) => void;
}

function DateSelect({ onChange }: Props) {
  const options: DateFilterType[] = [
    {
      id: 0,
      period: "Today",
    },
    {
      id: 1,
      period: "Last 7 Days",
    },
    {
      id: 2,
      period: "Last Month",
    },
    {
      id: 3,
      period: "Last 12 Months",
    },
    {
      id: 4,
      period: "All Time",
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(4);

  const onSelect = (value: number) => {
    setSelected(value);
    const period = options.find((option) => option.id === value)?.period;

    if (period) {
      onChange(period);
    }

    setDropdownOpen(false);
  };

  return (
    <Dropdown
      // @ts-ignore
      options={options.map((option) => ({
        key: option.id,
        label: option.period,
      }))}
      placement="bottomRight"
      onChange={onSelect}
      onVisibleChange={(state: boolean) => {
        setDropdownOpen(state);
      }}
    >
      <button
        className="btn justify-between min-w-44 bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600"
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex items-center gap-x-2">
          <Icon name="calendar" />
          <span>{options[selected].period}</span>
        </span>
        <Icon name={dropdownOpen ? "chevron-up" : "chevron-down"} />
      </button>
    </Dropdown>
  );
}

export default DateSelect;
